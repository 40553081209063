import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_CLINICS } from "redux/model/clinics/clinics/constants";
import { apiListClinics } from "api/clinic/clinicApi";
import { listClinicsFailed, listClinicsSuccess } from "redux/model/clinics/clinics/clinicsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";


function* clinicsSaga() {
  yield all([
    fork(watchListClinics),
  ]);
}

// List Clinics
export function* watchListClinics() {
  yield takeEvery(LIST_CLINICS, sagaListClinics);
}

export function* sagaListClinics() {
  try {
    const response = yield call(apiListClinics);

    yield put(listClinicsSuccess({ clinics: response.data }));

  } catch (e) {
    yield put(listClinicsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default clinicsSaga;
