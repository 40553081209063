import {
  ASSIGN_STAFF_EMAIL,
  ASSIGN_STAFF_FIRSTNAME,
  ASSIGN_STAFF_LASTNAME,
  ASSIGN_STAFF_GENDER,
  ASSIGN_STAFF_MOBILE,
  ASSIGN_STAFF_PASSWORD,
  ASSIGN_STAFF_USERNAME,
  ASSIGN_STAFF_ROLE,
  CREATE_STAFF,
  CREATE_STAFF_FAILED,
  CREATE_STAFF_SUCCESS,
  DELETE_STAFF,
  DELETE_STAFF_FAILED,
  DELETE_STAFF_SUCCESS,
  FETCH_STAFF,
  FETCH_STAFF_FAILED,
  FETCH_STAFF_SUCCESS,
  INIT_STAFF,
  UPDATE_STAFF,
  UPDATE_STAFF_FAILED,
  UPDATE_STAFF_SUCCESS, FETCH_CURRENT_STAFF, FETCH_CURRENT_STAFF_SUCCESS, FETCH_CURRENT_STAFF_FAILED
} from "redux/model/staffs/staff/constants";

export const initStaff = () => ({
  type: INIT_STAFF,
  payload: {}
});

export const createStaff = ({ staff }) => ({
  type: CREATE_STAFF,
  payload: { staff }
});

export const createStaffSuccess = ({ staff }) => ({
  type: CREATE_STAFF_SUCCESS,
  payload: { staff }
});

export const createStaffFailed = ({ error }) => ({
  type: CREATE_STAFF_FAILED,
  payload: { error }
});

export const fetchStaff = ({ staffId }) => ({
  type: FETCH_STAFF,
  payload: { staffId }
});

export const fetchStaffSuccess = ({ staff }) => ({
  type: FETCH_STAFF_SUCCESS,
  payload: { staff }
});

export const fetchStaffFailed = ({ error }) => ({
  type: FETCH_STAFF_FAILED,
  payload: { error }
});

export const updateStaff = ({ staff }) => ({
  type: UPDATE_STAFF,
  payload: { staff }
});

export const updateStaffSuccess = ({ staff }) => ({
  type: UPDATE_STAFF_SUCCESS,
  payload: { staff }
});

export const updateStaffFailed = ({ error }) => ({
  type: UPDATE_STAFF_FAILED,
  payload: { error }
});

export const deleteStaff = ({ staff }) => ({
  type: DELETE_STAFF,
  payload: { staff }
});

export const deleteStaffSuccess = ({ staff }) => ({
  type: DELETE_STAFF_SUCCESS,
  payload: { staff }
});

export const deleteStaffFailed = ({ error }) => ({
  type: DELETE_STAFF_FAILED,
  payload: { error }
});

export const assignStaffEmail = ({ email }) => ({
  type: ASSIGN_STAFF_EMAIL,
  payload: { email }
});

export const assignStaffUsername = ({ username }) => ({
  type: ASSIGN_STAFF_USERNAME,
  payload: { username }
});

export const assignStaffLastName = ({ lastName }) => ({
  type: ASSIGN_STAFF_LASTNAME,
  payload: { lastName }
});

export const assignStaffFirstName = ({ firstName }) => ({
  type: ASSIGN_STAFF_FIRSTNAME,
  payload: { firstName }
});

export const assignStaffGender = ({ gender }) => ({
  type: ASSIGN_STAFF_GENDER,
  payload: { gender }
});

export const assignStaffMobile = ({ mobile }) => ({
  type: ASSIGN_STAFF_MOBILE,
  payload: { mobile }
});

export const assignStaffPassword = ({ password }) => ({
  type: ASSIGN_STAFF_PASSWORD,
  payload: { password }
});

export const assignStaffRole = ({ role }) => ({
  type: ASSIGN_STAFF_ROLE,
  payload: { role }
});

export const fetchCurrentStaff = () => ({
  type: FETCH_CURRENT_STAFF,
  payload: {},
});

export const fetchCurrentStaffSuccess = ({ staff }) => ({
  type: FETCH_CURRENT_STAFF_SUCCESS,
  payload: { staff },
});

export const fetchCurrentStaffFailed = ({ error }) => ({
  type: FETCH_CURRENT_STAFF_FAILED,
  payload: { error },
});
