import {
  CREATE_GIFT_CARD_DECLARATION,
  CREATE_GIFT_CARD_DECLARATION_FAILED,
  CREATE_GIFT_CARD_DECLARATION_SUCCESS,
  DELETE_GIFT_CARD_DECLARATION, DELETE_GIFT_CARD_DECLARATION_FAILED, DELETE_GIFT_CARD_DECLARATION_SUCCESS,
  FETCH_GIFT_CARD_DECLARATION,
  FETCH_GIFT_CARD_DECLARATION_FAILED,
  FETCH_GIFT_CARD_DECLARATION_SUCCESS,
  INIT_GIFT_CARD_DECLARATION,
  UPDATE_GIFT_CARD_DECLARATION,
  UPDATE_GIFT_CARD_DECLARATION_FAILED,
  UPDATE_GIFT_CARD_DECLARATION_SUCCESS,
  ASSIGN_GIFT_CARD_DECLARATION
} from "redux/model/giftCardDeclarations/giftCardDeclaration/constants";

export const initGiftCardDeclaration = () => ({
  type: INIT_GIFT_CARD_DECLARATION,
  payload: {}
});

export const createGiftCardDeclaration = ({ giftCardDeclaration }) => ({
  type: CREATE_GIFT_CARD_DECLARATION,
  payload: { giftCardDeclaration }
});

export const createGiftCardDeclarationSuccess = ({ giftCardDeclaration }) => ({
  type: CREATE_GIFT_CARD_DECLARATION_SUCCESS,
  payload: { giftCardDeclaration }
});

export const createGiftCardDeclarationFailed = ({ error }) => ({
  type: CREATE_GIFT_CARD_DECLARATION_FAILED,
  payload: { error }
});

export const fetchGiftCardDeclaration = ({ giftCardDeclarationId }) => ({
  type: FETCH_GIFT_CARD_DECLARATION,
  payload: { giftCardDeclarationId }
});

export const fetchGiftCardDeclarationSuccess = ({ giftCardDeclaration }) => ({
  type: FETCH_GIFT_CARD_DECLARATION_SUCCESS,
  payload: { giftCardDeclaration }
});

export const fetchGiftCardDeclarationFailed = ({ error }) => ({
  type: FETCH_GIFT_CARD_DECLARATION_FAILED,
  payload: { error }
});

export const updateGiftCardDeclaration = ({ giftCardDeclaration }) => ({
  type: UPDATE_GIFT_CARD_DECLARATION,
  payload: { giftCardDeclaration }
});

export const updateGiftCardDeclarationSuccess = ({ giftCardDeclaration }) => ({
  type: UPDATE_GIFT_CARD_DECLARATION_SUCCESS,
  payload: { giftCardDeclaration }
});

export const updateGiftCardDeclarationFailed = ({ error }) => ({
  type: UPDATE_GIFT_CARD_DECLARATION_FAILED,
  payload: { error }
});

export const deleteGiftCardDeclaration = ({ giftCardDeclaration }) => ({
  type: DELETE_GIFT_CARD_DECLARATION,
  payload: { giftCardDeclaration }
});

export const deleteGiftCardDeclarationSuccess = ({ giftCardDeclaration }) => ({
  type: DELETE_GIFT_CARD_DECLARATION_SUCCESS,
  payload: { giftCardDeclaration }
});

export const deleteGiftCardDeclarationFailed = ({ error }) => ({
  type: DELETE_GIFT_CARD_DECLARATION_FAILED,
  payload: { error }
});

export const assignGiftCardDeclaration = ({ fields }) => ({
  type: ASSIGN_GIFT_CARD_DECLARATION,
  payload: { fields }
});
