import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { BASE_CLINIC_PATH, INDEX_CLINIC_PATH, NEW_CLINIC_PATH, EDIT_CLINIC_PATH } from "routes/clinic/constants";

const AllClinic = React.lazy(() => import('pages/clinics/all/Clinics'));
const NewClinic = React.lazy(() => import('pages/clinics/new/NewClinic'));
const EditClinic = React.lazy(() => import('pages/clinics/edit/EditClinic'));

export const clinicRoutes = {
  path: BASE_CLINIC_PATH,
  name: 'Clinics',
  icon: 'uil uil-shop',
  header: "Main Feature",
  children: [
    {
      path: INDEX_CLINIC_PATH,
      name: 'All Clinics',
      component: AllClinic,
      route: PrivateRoute,
    },
    {
      path: NEW_CLINIC_PATH,
      name: 'Add New',
      component: NewClinic,
      route: PrivateRoute,
    },
  ],
};

export const hiddenClinicRoutes = {
  children: [
    {
      path: EDIT_CLINIC_PATH,
      name: 'Edit Clinic',
      component: EditClinic,
      route: PrivateRoute,
    }
  ]
}
