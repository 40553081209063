import {
  FETCH_GIFT_CARD_DECLARATION,
  FETCH_GIFT_CARD_DECLARATION_FAILED,
  FETCH_GIFT_CARD_DECLARATION_SUCCESS,
  INIT_GIFT_CARD_DECLARATION,
  ASSIGN_GIFT_CARD_DECLARATION
} from "redux/model/giftCardDeclarations/giftCardDeclaration/constants";

const INIT_STATE = {
  giftCardDeclaration: {},
  loading: true,
  error: null,
};

const giftCardDeclarationReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_GIFT_CARD_DECLARATION:
      return INIT_STATE;

    case FETCH_GIFT_CARD_DECLARATION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_GIFT_CARD_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        giftCardDeclaration: action.payload.giftCardDeclaration,
      };

    case FETCH_GIFT_CARD_DECLARATION_FAILED:
      return {
        ...state,
        giftCardDeclaration: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_GIFT_CARD_DECLARATION:
      return {
        ...state,
        giftCardDeclaration: {
          ...state.giftCardDeclaration,
          ...action.payload.fields,
        }
      }

    default:
      return { ...state };
  }
};

export default giftCardDeclarationReducers;
