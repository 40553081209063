import {
  DOWNLOAD_ASSISTANT_REPORT_SUMMARY,
  LIST_ASSISTANT_REPORTS,
  LIST_ASSISTANT_REPORTS_FAILED,
  LIST_ASSISTANT_REPORTS_SUCCESS,
  LIST_ASSISTANT_REPORTS_ADMIN_DAILY_TOTAL,
  DOWNLOAD_ASSISTANT_REPORT_PRACTITIONER_SUMMARY
} from "redux/model/assistantReports/assistantReports/constants";

export const listAssistantReports = ({ clinicId }) => ({
  type: LIST_ASSISTANT_REPORTS,
  payload: { clinicId }
});

export const listAssistantReportsAdminDailyTotal = ({ date } = { date: new Date() }) => ({
  type: LIST_ASSISTANT_REPORTS_ADMIN_DAILY_TOTAL,
  payload: { date },
});

export const listAssistantReportsSuccess = ({ assistantReports }) => ({
  type: LIST_ASSISTANT_REPORTS_SUCCESS,
  payload: { assistantReports }
});

export const listAssistantReportsFailed = ({ error }) => ({
  type: LIST_ASSISTANT_REPORTS_FAILED,
  payload: { error }
});

export const downloadAssistantReportSummary = ({ from, to, clinic }) => ({
  type: DOWNLOAD_ASSISTANT_REPORT_SUMMARY,
  payload: { from, to, clinic }
});

export const downloadAssistantReportPractitionerSummary = ({ from, to }) => ({
  type: DOWNLOAD_ASSISTANT_REPORT_PRACTITIONER_SUMMARY,
  payload: { from, to }
});
