import {
  axios,
  extractIdFrom,
  generateRoute,
  toApiDateString,
  toApiDateTimeString, toMomentFromTimeString
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_ASSISTANT_REPORT_API,
  LIST_ASSISTANT_REPORT_API,
  FETCH_ASSISTANT_REPORT_API,
  UPDATE_ASSISTANT_REPORT_API,
  DOWNLOAD_ASSISTANT_REPORT_SUMMARY_API,
  ASSISTANT_REPORT_ADMIN_DAILY_TOTAL_API,
  UNLOCK_ASSISTANT_REPORT_API, DOWNLOAD_ASSISTANT_REPORT_PRACTITIONER_SUMMARY_API, ASSISTANT_REPORT_STATISTICS_API
} from "api/assistantReport/constants";
import _ from 'lodash';


export const apiListAssistantReports = ({ clinicId }) => {
  const { url, method } = LIST_ASSISTANT_REPORT_API;

  return axios({
    method,
    url,
    params: { clinicId: extractIdFrom(clinicId) },
  });
};

export const apiCreateAssistantReport = ({ assistantReport }) => {
  const { url, method } = CREATE_ASSISTANT_REPORT_API;

  return axios({
    method,
    url,
    data: assistantReportParams(assistantReport),
  });
};

export const apiFetchAssistantReport = ({ assistantReportId }) => {
  const { url, method } = FETCH_ASSISTANT_REPORT_API;

  return axios({
    method,
    url: generateRoute(url, assistantReportId),
  });
};

export const apiUpdateAssistantReport = ({ assistantReport }) => {
  const { url, method } = UPDATE_ASSISTANT_REPORT_API;

  return axios({
    method,
    url: generateRoute(url, assistantReport.id),
    data: _.omit(assistantReportParams(assistantReport), 'practitionerReports'),
  });
};

export const apiDownloadAssistantReportSummary = ({ from, to, clinic }) => {
  const { url, method } = DOWNLOAD_ASSISTANT_REPORT_SUMMARY_API;

  return axios({
    method,
    url,
    params: { from, to, clinic: extractIdFrom(clinic.id) },
  });
};

export const apiDownloadAssistantReportPractitionerSummary = ({ from, to }) => {
  const { url, method } = DOWNLOAD_ASSISTANT_REPORT_PRACTITIONER_SUMMARY_API;

  return axios({
    method,
    url,
    params: { from, to },
  });
};

export const apiAssistantReportAdminDailyTotal = ({ date }) => {
  const { url, method } = ASSISTANT_REPORT_ADMIN_DAILY_TOTAL_API;

  return axios({
    method,
    url,
    params: { date: toApiDateString(date) }
  })
};

export const apiUnlockAssistantReport = ({ assistantReport }) => {
  const { url, method } = UNLOCK_ASSISTANT_REPORT_API;

  return axios({
    method,
    url: generateRoute(url, assistantReport.id),
  });
};

export const apiAssistantReportStatistics = () => {
  const { url, method } = ASSISTANT_REPORT_STATISTICS_API;

  return axios({
    method,
    url,
  });
};

const assistantReportParams = (assistantReport) => {
  return {
    ...assistantReport,
    practitionerReports: null,
    date: toApiDateString(assistantReport.date),
    staffId: extractIdFrom(assistantReport.staffId),
  };
};
