import { ERROR_FORM_CLINIC, LOADING_FORM_CLINIC, RESET_FORM_CLINIC } from "redux/ui/form/clinic/constants";
import {
  CREATE_CLINIC,
  CREATE_CLINIC_FAILED,
  CREATE_CLINIC_SUCCESS,
  UPDATE_CLINIC, UPDATE_CLINIC_FAILED, UPDATE_CLINIC_SUCCESS
} from "redux/model/clinics/clinic/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formClinicReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_CLINIC:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_CLINIC:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_CLINIC:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_CLINIC:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_CLINIC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_CLINIC_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_CLINIC:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_CLINIC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_CLINIC_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formClinicReducer;
