import { combineReducers } from 'redux';
import clinicReducers from "redux/model/clinics/clinic/clinicReducers";
import clinicsReducers from "redux/model/clinics/clinics/clinicsReducers";
import giftCardReducers from "redux/model/giftCards/giftCard/giftCardReducers";
import giftCardsReducers from "redux/model/giftCards/giftCards/giftCardsReducers";
import practitionerReducers from "redux/model/practitioners/practitioner/practitionerReducers";
import practitionersReducers from "redux/model/practitioners/practitioners/practitionersReducers";
import staffReducers from "redux/model/staffs/staff/staffReducers";
import staffsReducers from "redux/model/staffs/staffs/staffsReducers";
import noticesReducer from "redux/model/notices/notices/noticesReducers";
import assistantReportReducers from "redux/model/assistantReports/assistantReport/assistantReportReducers";
import assistantReportsReducers from "redux/model/assistantReports/assistantReports/assistantReportsReducers";
import practitionerReportItemsReducers
  from "redux/model/practitionerReportItems/practitionerReportItems/practitionerReportItemsReducers";
import practitionerReportReducers from "redux/model/practitionerReports/practitionerReport/practitionerReportReducers";
import practitionerReportsReducers
  from "redux/model/practitionerReports/practitionerReports/practitionerReportsReducers";
import surveysReducer from "redux/model/surveys/surveys/surveysReducers";
import consultationsReducers from "redux/model/consultations/consultations/consultationsReducers";
import statisticReducers from "redux/model/statistics/statistic/statisticReducers";
import productGiftCardReducers from "redux/model/productGiftCards/productGiftCard/productGiftCardReducers";
import productGiftCardsReducers from "redux/model/productGiftCards/productGiftCards/productGiftCardsReducers";
import transactionsReducers from "redux/model/transactions/transactions/transactionsReducers";
import giftCardDeclarationReducers
  from "redux/model/giftCardDeclarations/giftCardDeclaration/giftCardDeclarationReducers";
import giftCardDeclarationsReducers
  from "redux/model/giftCardDeclarations/giftCardDeclarations/giftCardDeclarationsReducers";

export default combineReducers({
  clinic: clinicReducers,
  clinics: clinicsReducers,

  giftCard: giftCardReducers,
  giftCards: giftCardsReducers,

  practitioner: practitionerReducers,
  practitioners: practitionersReducers,

  staff: staffReducers,
  staffs: staffsReducers,

  notices: noticesReducer,

  assistantReport: assistantReportReducers,
  assistantReports: assistantReportsReducers,
  practitionerReportItems: practitionerReportItemsReducers,
  practitionerReport: practitionerReportReducers,
  practitionerReports: practitionerReportsReducers,

  surveys: surveysReducer,

  consultations: consultationsReducers,

  statistic: statisticReducers,

  productGiftCard: productGiftCardReducers,
  productGiftCards: productGiftCardsReducers,

  giftCardDeclaration: giftCardDeclarationReducers,
  giftCardDeclarations: giftCardDeclarationsReducers,

  transactions: transactionsReducers,

});
