import {
  ASSIGN_PRACTITIONER_EMAIL,
  ASSIGN_PRACTITIONER_FIRSTNAME,
  ASSIGN_PRACTITIONER_LASTNAME,
  ASSIGN_PRACTITIONER_MOBILE,
  ASSIGN_PRACTITIONER_PASSWORD,
  ASSIGN_PRACTITIONER_STATE,
  ASSIGN_PRACTITIONER_USERNAME,
  ASSIGN_PRACTITIONER_COMMISSION,
  CREATE_PRACTITIONER,
  CREATE_PRACTITIONER_FAILED,
  CREATE_PRACTITIONER_SUCCESS,
  DELETE_PRACTITIONER,
  DELETE_PRACTITIONER_FAILED,
  DELETE_PRACTITIONER_SUCCESS,
  FETCH_PRACTITIONER,
  FETCH_PRACTITIONER_FAILED,
  FETCH_PRACTITIONER_SUCCESS,
  INIT_PRACTITIONER,
  UPDATE_PRACTITIONER,
  UPDATE_PRACTITIONER_FAILED,
  UPDATE_PRACTITIONER_SUCCESS
} from "redux/model/practitioners/practitioner/constants";

export const initPractitioner = () => ({
  type: INIT_PRACTITIONER,
  payload: {}
});

export const createPractitioner = ({ practitioner }) => ({
  type: CREATE_PRACTITIONER,
  payload: { practitioner }
});

export const createPractitionerSuccess = ({ practitioner }) => ({
  type: CREATE_PRACTITIONER_SUCCESS,
  payload: { practitioner }
});

export const createPractitionerFailed = ({ error }) => ({
  type: CREATE_PRACTITIONER_FAILED,
  payload: { error }
});

export const fetchPractitioner = ({ practitionerId }) => ({
  type: FETCH_PRACTITIONER,
  payload: { practitionerId }
});

export const fetchPractitionerSuccess = ({ practitioner }) => ({
  type: FETCH_PRACTITIONER_SUCCESS,
  payload: { practitioner }
});

export const fetchPractitionerFailed = ({ error }) => ({
  type: FETCH_PRACTITIONER_FAILED,
  payload: { error }
});

export const updatePractitioner = ({ practitioner }) => ({
  type: UPDATE_PRACTITIONER,
  payload: { practitioner }
});

export const updatePractitionerSuccess = ({ practitioner }) => ({
  type: UPDATE_PRACTITIONER_SUCCESS,
  payload: { practitioner }
});

export const updatePractitionerFailed = ({ error }) => ({
  type: UPDATE_PRACTITIONER_FAILED,
  payload: { error }
});

export const deletePractitioner = ({ practitioner }) => ({
  type: DELETE_PRACTITIONER,
  payload: { practitioner }
});

export const deletePractitionerSuccess = ({ practitioner }) => ({
  type: DELETE_PRACTITIONER_SUCCESS,
  payload: { practitioner }
});

export const deletePractitionerFailed = ({ error }) => ({
  type: DELETE_PRACTITIONER_FAILED,
  payload: { error }
});

export const assignPractitionerEmail = ({ email }) => ({
  type: ASSIGN_PRACTITIONER_EMAIL,
  payload: { email }
});

export const assignPractitionerUsername = ({ username }) => ({
  type: ASSIGN_PRACTITIONER_USERNAME,
  payload: { username }
});

export const assignPractitionerPassword = ({ password }) => ({
  type: ASSIGN_PRACTITIONER_PASSWORD,
  payload: { password }
});

export const assignPractitionerFirstName = ({ firstName }) => ({
  type: ASSIGN_PRACTITIONER_FIRSTNAME,
  payload: { firstName }
});


export const assignPractitionerLastName = ({ lastName }) => ({
  type: ASSIGN_PRACTITIONER_LASTNAME,
  payload: { lastName }
});

export const assignPractitionerMobile = ({ mobile }) => ({
  type: ASSIGN_PRACTITIONER_MOBILE,
  payload: { mobile }
});

export const assignPractitionerState = ({ state }) => ({
  type: ASSIGN_PRACTITIONER_STATE,
  payload: { state }
});

export const assignPractitionerCommission = ({ commission }) => ({
  type: ASSIGN_PRACTITIONER_COMMISSION,
  payload: { commission }
});
