
export const CREATE_GIFT_CARD_DECLARATION_API = {
  url: `/api/system/gift_card_declarations`,
  method: 'post'
};

export const INDEX_GIFT_CARD_DECLARATIONS_API = {
  url: `/api/system/gift_card_declarations`,
  method: 'get',
};

export const FETCH_GIFT_CARD_DECLARATION_API = {
  url: `/api/system/gift_card_declarations/:id`,
  method: 'get',
};

export const UPDATE_GIFT_CARD_DECLARATION_API = {
  url: `/api/system/gift_card_declarations/:id`,
  method: 'put'
};

export const DELETE_GIFT_CARD_DECLARATION_API = {
  url: `/api/system/gift_card_declarations/:id`,
  method: 'delete'
};
