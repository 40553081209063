import { axios, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { CREATE_LOGO_API, UPDATE_LOGO_API } from "api/logo/constants";


export const apiCreateLogo = ({ logo, service }) => {
  const { url, method } = CREATE_LOGO_API;

  return axios({
    method,
    url,
    data: {
      logo: { ...logoParams(logo), service },
    },
  });
};

export const apiUpdateLogo = ({ logo, service }) => {
  const { url, method } = UPDATE_LOGO_API;

  return axios({
    method,
    url: generateRoute(url, logo.sourceId),
    data: {
      logo: { ...logoParams(logo), service },
    },
  });
};

const logoParams = (logo) => {
  const {
    file,
  } = logo;

  return {
    file,
  };
};
