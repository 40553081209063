import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { BASE_PRACTITIONER_PATH, INDEX_PRACTITIONER_PATH, NEW_PRACTITIONER_PATH, EDIT_PRACTITIONER_PATH } from "routes/practitioner/constants";

const AllPractitioner = React.lazy(() => import('pages/practitioners/all/Practitioners'));
const NewPractitioner = React.lazy(() => import('pages/practitioners/new/NewPractitioner'));
const EditPractitioner = React.lazy(() => import('pages/practitioners/edit/EditPractitioner'));

export const practitionerRoutes = {
  path: BASE_PRACTITIONER_PATH,
  name: 'Therapist',
  icon: 'mdi mdi-doctor',
  children: [
    {
      path: INDEX_PRACTITIONER_PATH,
      name: 'All Therapists',
      component: AllPractitioner,
      route: PrivateRoute,
    },
    {
      path: NEW_PRACTITIONER_PATH,
      name: 'Add New',
      component: NewPractitioner,
      route: PrivateRoute,
    },
  ],
};

export const hiddenPractitionerRoutes = {
  children: [
    {
      path: EDIT_PRACTITIONER_PATH,
      name: 'Edit Therapist',
      component: EditPractitioner,
      route: PrivateRoute,
    }
  ]
}
