import { all, call, delay, fork, put, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";
import { apiCreateLogo, apiUpdateLogo } from "api/logo/logoApi";
import { toastError, toastSaved } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import { CREATE_LOGO, UPDATE_LOGO } from "redux/model/general/logo/constants";
import {
  createLogoFailed,
  createLogoSuccess,
  updateLogoFailed,
  updateLogoSuccess
} from "redux/model/general/logo/logoActions";
import {
  closeFirstLayerModal,
  startFirstLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { assignClinicLogo } from "redux/model/clinics/clinic/clinicActions";

function* logoSaga() {
  yield all([
    fork(watchCreateLogo),
    fork(watchUpdateLogo),
  ]);
}

// Create Logo
export function* watchCreateLogo() {
  yield takeLeading(CREATE_LOGO, sagaCreateLogo);
}

export function* sagaCreateLogo({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateLogo, payload);

    yield put(createLogoSuccess({ logo: response.data }));
    yield put(toastSaved({ name: 'logo' }));

    switch (payload.service) {
      case 'clinic':
        yield put(assignClinicLogo({ logo: response.data }));
        break;
      default:
      // do nothing
    }

    yield put(closeFirstLayerModal());
    yield put(startFirstLayerModalListening());

  } catch (e) {
    yield put(createLogoFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Logo
export function* watchUpdateLogo() {
  yield takeLeading(UPDATE_LOGO, sagaUpdateLogo);
}

export function* sagaUpdateLogo({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateLogo, payload);

    yield put(updateLogoSuccess({ logo: response.data }));
    yield put(toastSaved({ name: 'logo' }));

    switch (payload.service) {
      case 'clinic':
        yield put(assignClinicLogo({ logo: response.data }));
        break;
      default:
      // do nothing
    }

    yield put(closeFirstLayerModal());
    yield put(startFirstLayerModalListening());

  } catch (e) {
    yield put(updateLogoFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

export default logoSaga;
