import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoutes, PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { clinicRoutes, hiddenClinicRoutes } from "routes/clinic/routes";
import { giftCardRoutes, hiddenGiftCardRoutes } from "routes/giftCard/routes";
import { INDEX_CLINIC_PATH } from "routes/clinic/constants";
import { hiddenPractitionerRoutes, practitionerRoutes } from "routes/practitioner/routes";
import { hiddenStaffRoutes, staffRoutes } from "routes/staff/routes";
import { hiddenNoticeRoutes, noticeRoutes } from "routes/notice/routes";
import { assistantReportRoutes, hiddenAssistantReportRoutes } from "routes/assistantReport/routes";
import { dashboardRoutes, hiddenDashboardRoutes } from "routes/dashboard/routes";
import {
  OVERVIEW_DASHBOARD_PATH,
} from "routes/dashboard/constants";
import { hiddenStatisticsRoutes, statisticsRoutes } from "routes/statistics/routes";
import { hiddenProductGiftCardRoutes, productGiftCardRoutes } from "routes/productGiftCard/routes";

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to={OVERVIEW_DASHBOARD_PATH} />,
  route: PrivateRoute,
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach(item => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const hiddenRoutes = {
  children: [
    ...hiddenDashboardRoutes.children,
    ...hiddenClinicRoutes.children,
    ...hiddenGiftCardRoutes.children,
    ...hiddenPractitionerRoutes.children,
    ...hiddenStaffRoutes.children,
    ...hiddenNoticeRoutes.children,
    ...hiddenAssistantReportRoutes.children,
    ...hiddenStatisticsRoutes.children,
    ...hiddenProductGiftCardRoutes.children,
  ],
};

// All routes
const allRoutes = [
  rootRoute,
  dashboardRoutes,
  authRoutes,
  staffRoutes,
  clinicRoutes,
  giftCardRoutes,
  practitionerRoutes,
  noticeRoutes,
  statisticsRoutes,
  productGiftCardRoutes,
  hiddenRoutes,
];

const authProtectedRoutes = [
  dashboardRoutes,
  rootRoute,
  clinicRoutes,
  staffRoutes,
  practitionerRoutes,
  giftCardRoutes,
  noticeRoutes,
  statisticsRoutes,
  productGiftCardRoutes,
];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
