import { ERROR_FORM_PRACTITIONER, LOADING_FORM_PRACTITIONER, RESET_FORM_PRACTITIONER } from "redux/ui/form/practitioner/constants";
import {
  CREATE_PRACTITIONER,
  CREATE_PRACTITIONER_FAILED,
  CREATE_PRACTITIONER_SUCCESS,
  UPDATE_PRACTITIONER, UPDATE_PRACTITIONER_FAILED, UPDATE_PRACTITIONER_SUCCESS
} from "redux/model/practitioners/practitioner/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formPractitionerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PRACTITIONER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PRACTITIONER:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PRACTITIONER:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PRACTITIONER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PRACTITIONER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PRACTITIONER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PRACTITIONER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PRACTITIONER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PRACTITIONER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formPractitionerReducer;
