
export const CREATE_ASSISTANT_REPORT_API = {
  url: `/api/assistant_reports`,
  method: 'post'
};

export const LIST_ASSISTANT_REPORT_API = {
  url: `/api/assistant_reports`,
  method: 'get',
}

export const FETCH_ASSISTANT_REPORT_API = {
  url: `/api/assistant_reports/:id`,
  method: 'get',
}

export const UPDATE_ASSISTANT_REPORT_API = {
  url: `/api/assistant_reports/:id`,
  method: 'put'
}

export const DOWNLOAD_ASSISTANT_REPORT_SUMMARY_API = {
  url: `/api/assistant_report/summary_download`,
  method: 'get'
}

export const DOWNLOAD_ASSISTANT_REPORT_PRACTITIONER_SUMMARY_API = {
  url: `/api/assistant_report/practitioner_summary_download`,
  method: 'get'
}

export const ASSISTANT_REPORT_ADMIN_DAILY_TOTAL_API = {
  url: `/api/assistant_report/admin_daily_total`,
  method: 'get'
}

export const UNLOCK_ASSISTANT_REPORT_API = {
  url: `/api/assistant_report/unlock/:id`,
  method: 'post'
};

export const ASSISTANT_REPORT_STATISTICS_API = {
  url: `/api/assistant_report/statistics`,
  method: 'get'
};
