export const INIT_GIFT_CARD_DECLARATION = 'INIT_GIFT_CARD_DECLARATION';

export const FETCH_GIFT_CARD_DECLARATION = 'FETCH_GIFT_CARD_DECLARATION';
export const FETCH_GIFT_CARD_DECLARATION_SUCCESS = 'FETCH_GIFT_CARD_DECLARATION_SUCCESS';
export const FETCH_GIFT_CARD_DECLARATION_FAILED = 'FETCH_GIFT_CARD_DECLARATION_FAILED';

export const CREATE_GIFT_CARD_DECLARATION = 'CREATE_GIFT_CARD_DECLARATION';
export const CREATE_GIFT_CARD_DECLARATION_SUCCESS = 'CREATE_GIFT_CARD_DECLARATION_SUCCESS';
export const CREATE_GIFT_CARD_DECLARATION_FAILED = 'CREATE_GIFT_CARD_DECLARATION_FAILED';

export const UPDATE_GIFT_CARD_DECLARATION = 'UPDATE_GIFT_CARD_DECLARATION';
export const UPDATE_GIFT_CARD_DECLARATION_SUCCESS = 'UPDATE_GIFT_CARD_DECLARATION_SUCCESS';
export const UPDATE_GIFT_CARD_DECLARATION_FAILED = 'UPDATE_GIFT_CARD_DECLARATION_FAILED';

export const DELETE_GIFT_CARD_DECLARATION = 'DELETE_GIFT_CARD_DECLARATION';
export const DELETE_GIFT_CARD_DECLARATION_SUCCESS = 'DELETE_GIFT_CARD_DECLARATION_SUCCESS';
export const DELETE_GIFT_CARD_DECLARATION_FAILED = 'DELETE_GIFT_CARD_DECLARATION_FAILED';

export const ASSIGN_GIFT_CARD_DECLARATION = 'ASSIGN_GIFT_CARD_DECLARATION';
