import { axios, extractIdFrom } from "@tmatt-tech/allaroundmassage_frontend_common";
import { LIST_SURVEY_API, } from "api/survey/constants";


export const apiListSurveys = ({ clinicId }) => {
  const { url, method } = LIST_SURVEY_API;

  return axios({
    method,
    url,
    params: { clinicId: extractIdFrom(clinicId) },
  });
};
