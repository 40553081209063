import { LIST_CONSULTATIONS, LIST_CONSULTATIONS_FAILED, LIST_CONSULTATIONS_SUCCESS } from "redux/model/consultations/consultations/constants";

export const listConsultations = () => ({
  type: LIST_CONSULTATIONS,
  payload: {}
});

export const listConsultationsSuccess = ({ consultations }) => ({
  type: LIST_CONSULTATIONS_SUCCESS,
  payload: { consultations }
});

export const listConsultationsFailed = ({ error }) => ({
  type: LIST_CONSULTATIONS_FAILED,
  payload: { error }
});
