import React from "react";
import { INDEX_PRODUCT_GIFT_CARDS_PATH, SHOW_PRODUCT_GIFT_CARD_PATH } from "routes/productGiftCard/constants";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";

const ProductGiftCards = React.lazy(() => import('pages/productGiftCards/index/ProductGiftCards'));
const ShowProductGiftCard = React.lazy(() => import('pages/productGiftCards/show/ShowProductGiftCard'));

export const productGiftCardRoutes = {
  path: INDEX_PRODUCT_GIFT_CARDS_PATH,
  name: 'ProductGiftCards',
  icon: 'uil uil-tag-alt',
  exact: true,
  component: ProductGiftCards,
  route: PrivateRoute,
};

export const hiddenProductGiftCardRoutes = {
  children: [
    {
      path: SHOW_PRODUCT_GIFT_CARD_PATH,
      exact: true,
      name: 'Show ProductGiftCard',
      component: ShowProductGiftCard,
      route: PrivateRoute,
    }
  ]
}
