export const INIT_ASSISTANT_REPORT = 'INIT_ASSISTANT_REPORT';

export const CREATE_ASSISTANT_REPORT = 'CREATE_ASSISTANT_REPORT';
export const CREATE_ASSISTANT_REPORT_SUCCESS = 'CREATE_ASSISTANT_REPORT_SUCCESS';
export const CREATE_ASSISTANT_REPORT_FAILED = 'CREATE_ASSISTANT_REPORT_FAILED';

export const FETCH_ASSISTANT_REPORT = 'FETCH_ASSISTANT_REPORT';
export const FETCH_ASSISTANT_REPORT_SUCCESS = 'FETCH_ASSISTANT_REPORT_SUCCESS';
export const FETCH_ASSISTANT_REPORT_FAILED = 'FETCH_ASSISTANT_REPORT_FAILED';

export const UPDATE_ASSISTANT_REPORT = 'UPDATE_ASSISTANT_REPORT';
export const UPDATE_ASSISTANT_REPORT_SUCCESS = 'UPDATE_ASSISTANT_REPORT_SUCCESS';
export const UPDATE_ASSISTANT_REPORT_FAILED = 'UPDATE_ASSISTANT_REPORT_FAILED';

export const UNLOCK_ASSISTANT_REPORT = 'UNLOCK_ASSISTANT_REPORT';
export const UNLOCK_ASSISTANT_REPORT_SUCCESS = 'UNLOCK_ASSISTANT_REPORT_SUCCESS';
export const UNLOCK_ASSISTANT_REPORT_FAILED = 'UNLOCK_ASSISTANT_REPORT_FAILED';
