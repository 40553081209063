import _ from 'lodash';
import {
  CREATE_STAFF_SUCCESS,
  DELETE_STAFF_SUCCESS,
  UPDATE_STAFF_SUCCESS
} from "redux/model/staffs/staff/constants";
import { LIST_STAFFS, LIST_STAFFS_FAILED, LIST_STAFFS_SUCCESS } from "redux/model/staffs/staffs/constants";

const INIT_STATE = {
  staffs: {},
  loading: true,
  error: null,
};

const staffsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_STAFFS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_STAFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        staffs: _.mapKeys(action.payload.staffs, 'id'),
      };

    case LIST_STAFFS_FAILED:
      return {
        ...state,
        staffs: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_STAFF_SUCCESS:
      return {
        ...state,
        staffs: {
          [action.payload.staff.id]: action.payload.staff,
          ...state.staffs,
        },
      };

    case UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        staffs: {
          ...state.staffs,
          [action.payload.staff.id]: action.payload.staff,
        },
      };

    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        staffs: _.omit(state.staffs, action.payload.staff.id)
      };

    default:
      return { ...state };
  }
};

export default staffsReducer;
