import {
  axios,
  extractIdFrom,
  generateRoute,
  toApiDateTimeString,
  toMomentFromTimeString
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_PRACTITIONER_REPORT_API,
  LIST_PRACTITIONER_REPORT_API,
  UPDATE_PRACTITIONER_REPORT_API
} from "api/practitionerReport/constants";
import _ from 'lodash';


export const apiListPractitionerReports = () => {
  const { url, method } = LIST_PRACTITIONER_REPORT_API;

  return axios({
    method,
    url,
  });
};

export const apiCreatePractitionerReport = ({ assistantReport, practitionerReport }) => {
  const { url, method } = CREATE_PRACTITIONER_REPORT_API;

  return axios({
    method,
    url,
    data: {
      assistantReport: extractIdFrom(assistantReport.id),
      practitionerReport: practitionerReportParams(practitionerReport)
    },
  });
};

export const apiUpdatePractitionerReport = ({ assistantReport, practitionerReport }) => {
  const { url, method } = UPDATE_PRACTITIONER_REPORT_API;

  return axios({
    method,
    url: generateRoute(url, practitionerReport.id),
    data: {
      assistantReport: extractIdFrom(assistantReport.id),
      practitionerReport: practitionerReportParams(practitionerReport)
    },
  });
};

const practitionerReportParams = (practitionerReport) => {
  return {
    practitionerId: extractIdFrom(practitionerReport.practitionerId),
    commission: practitionerReport.commission,
    wage: practitionerReport.wage,
    practitionerReportItems: _.map(practitionerReport.practitionerReportItems, (reportItem) => {
      return {
        ...reportItem,
        voucherNumber: _.toUpper(reportItem.voucherNumber),
        appointmentTime: toApiDateTimeString(toMomentFromTimeString(reportItem.appointmentTime)),
      };
    }),
  }
};
