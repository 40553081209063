export const INIT_CLINIC = 'INIT_CLINIC';

export const CREATE_CLINIC = 'CREATE_CLINIC';
export const CREATE_CLINIC_SUCCESS = 'CREATE_CLINIC_SUCCESS';
export const CREATE_CLINIC_FAILED = 'CREATE_CLINIC_FAILED';

export const FETCH_CLINIC = 'FETCH_CLINIC';
export const FETCH_CLINIC_SUCCESS = 'FETCH_CLINIC_SUCCESS';
export const FETCH_CLINIC_FAILED = 'FETCH_CLINIC_FAILED';

export const UPDATE_CLINIC = 'UPDATE_CLINIC';
export const UPDATE_CLINIC_SUCCESS = 'UPDATE_CLINIC_SUCCESS';
export const UPDATE_CLINIC_FAILED = 'UPDATE_CLINIC_FAILED';

export const DELETE_CLINIC = 'DELETE_CLINIC';
export const DELETE_CLINIC_SUCCESS = 'DELETE_CLINIC_SUCCESS';
export const DELETE_CLINIC_FAILED = 'DELETE_CLINIC_FAILED';

export const ASSIGN_CLINIC_EMAIL = 'ASSIGN_CLINIC_EMAIL';
export const ASSIGN_CLINIC_USERNAME = 'ASSIGN_CLINIC_USERNAME';
export const ASSIGN_CLINIC_PASSWORD = 'ASSIGN_CLINIC_PASSWORD';
export const ASSIGN_CLINIC_NAME = 'ASSIGN_CLINIC_NAME';
export const ASSIGN_CLINIC_PHONE = 'ASSIGN_CLINIC_PHONE';
export const ASSIGN_CLINIC_MOBILE = 'ASSIGN_CLINIC_MOBILE';
export const ASSIGN_CLINIC_LOCATION = 'ASSIGN_CLINIC_LOCATION';
export const ASSIGN_CLINIC_BUSINESS_NAME = 'ASSIGN_CLINIC_BUSINESS_NAME';
export const ASSIGN_CLINIC_ABN = 'ASSIGN_CLINIC_ABN';
export const ASSIGN_CLINIC_OVERVIEW = 'ASSIGN_CLINIC_OVERVIEW';
export const ASSIGN_CLINIC_LOGO = 'ASSIGN_CLINIC_LOGO';
export const ASSIGN_CLINIC_SHOP_MANAGER = 'ASSIGN_CLINIC_SHOP_MANAGER';
export const ASSIGN_CLINIC_REGIONAL_MANAGER = 'ASSIGN_CLINIC_REGIONAL_MANAGER';
export const ASSIGN_CLINIC_VOUCHER_PREFIX = 'ASSIGN_CLINIC_VOUCHER_PREFIX';
