import {
  CREATE_PRODUCT_GIFT_CARD_API,
  DELETE_PRODUCT_GIFT_CARD_API,
  FETCH_PRODUCT_GIFT_CARD_API,
  INDEX_PRODUCT_GIFT_CARDS_API,
  SYNC_SHOPIFY_PRODUCT_GIFT_CARDS_API,
  UPDATE_PRODUCT_GIFT_CARD_API
} from "api/productGiftCard/constants";
import { axios, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";

export const apiListProductGiftCards = () => {
  const { url, method } = INDEX_PRODUCT_GIFT_CARDS_API;

  return axios({
    method,
    url,
  });
};

export const apiCreateProductGiftCard = ({ productGiftCard }) => {
  const { url, method } = CREATE_PRODUCT_GIFT_CARD_API;

  return axios({
    method,
    url,
    data: productGiftCardParams({ productGiftCard }),
  });
};

export const apiFetchProductGiftCard = ({ productGiftCardId }) => {
  const { url, method } = FETCH_PRODUCT_GIFT_CARD_API;

  return axios({
    method,
    url: generateRoute(url, productGiftCardId),
  });
};

export const apiUpdateProductGiftCard = ({ productGiftCard }) => {
  const { url, method } = UPDATE_PRODUCT_GIFT_CARD_API;

  return axios({
    method,
    url: generateRoute(url, productGiftCard.id),
    data: productGiftCardParams({ productGiftCard }),
  });
};

export const apiDeleteProductGiftCard = ({ productGiftCard }) => {
  const { url, method } = DELETE_PRODUCT_GIFT_CARD_API;

  return axios({
    method,
    url: generateRoute(url, productGiftCard.id),
  });
};

export const apiSyncShopifyProductGiftCards = () => {
  const { url, method } = SYNC_SHOPIFY_PRODUCT_GIFT_CARDS_API;

  return axios({
    method,
    url,
  });
};

const productGiftCardParams = ({ productGiftCard }) => {
  const {
    systemPrice,
    systemVariety,
    expiryPeriod,
    description,
    minuteOrDollar,
    imageLink,
  } = productGiftCard;

  return {
    productGiftCard: {
      systemPrice,
      systemVariety: systemVariety || 'Minutes',
      expiryPeriod,
      description,
      minuteOrDollar,
      imageLink,
    }
  }
};
