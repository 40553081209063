export const INIT_STAFF = 'INIT_STAFF';

export const CREATE_STAFF = 'CREATE_STAFF';
export const CREATE_STAFF_SUCCESS = 'CREATE_STAFF_SUCCESS';
export const CREATE_STAFF_FAILED = 'CREATE_STAFF_FAILED';

export const FETCH_STAFF = 'FETCH_STAFF';
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_FAILED = 'FETCH_STAFF_FAILED';

export const UPDATE_STAFF = 'UPDATE_STAFF';
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAILED = 'UPDATE_STAFF_FAILED';

export const DELETE_STAFF = 'DELETE_STAFF';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAILED = 'DELETE_STAFF_FAILED';

export const ASSIGN_STAFF_EMAIL = 'ASSIGN_STAFF_EMAIL';
export const ASSIGN_STAFF_USERNAME = 'ASSIGN_STAFF_USERNAME';
export const ASSIGN_STAFF_PASSWORD = 'ASSIGN_STAFF_PASSWORD';
export const ASSIGN_STAFF_FIRSTNAME = 'ASSIGN_STAFF_FIRSTNAME';
export const ASSIGN_STAFF_LASTNAME = 'ASSIGN_STAFF_LASTNAME';
export const ASSIGN_STAFF_GENDER = 'ASSIGN_STAFF_GENDER';
export const ASSIGN_STAFF_MOBILE = 'ASSIGN_STAFF_MOBILE';
export const ASSIGN_STAFF_ROLE = 'ASSIGN_STAFF_ROLE';

export const FETCH_CURRENT_STAFF = 'FETCH_CURRENT_STAFF';
export const FETCH_CURRENT_STAFF_SUCCESS = 'FETCH_CURRENT_STAFF_SUCCESS';
export const FETCH_CURRENT_STAFF_FAILED = 'FETCH_CURRENT_STAFF_FAILED';
