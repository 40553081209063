import _ from 'lodash';
import {
  LIST_CONSULTATIONS,
  LIST_CONSULTATIONS_FAILED,
  LIST_CONSULTATIONS_SUCCESS
} from "redux/model/consultations/consultations/constants";

const INIT_STATE = {
  consultations: {},
  loading: true,
  error: null,
};

const consultationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CONSULTATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        consultations: _.mapKeys(action.payload.consultations, 'id'),
      };

    case LIST_CONSULTATIONS_FAILED:
      return {
        ...state,
        consultations: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default consultationsReducer;
