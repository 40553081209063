import { axios, extractIdFrom, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_CLINIC_API,
  LIST_CLINIC_API,
  FETCH_CLINIC_API,
  DELETE_CLINIC_API,
  UPDATE_CLINIC_API
} from "api/clinic/constants";


export const apiListClinics = () => {
  const { url, method } = LIST_CLINIC_API;

  return axios({
    method,
    url,
  });
};

export const apiCreateClinic = ({ clinic }) => {
  const { url, method } = CREATE_CLINIC_API;

  return axios({
    method,
    url,
    data: clinicParams(clinic),
  });
};

export const apiFetchClinic = ({ clinicId }) => {
  const { url, method } = FETCH_CLINIC_API;

  return axios({
    method,
    url: generateRoute(url, clinicId),
  });
};

export const apiUpdateClinic = ({ clinic }) => {
  const { url, method } = UPDATE_CLINIC_API;

  return axios({
    method,
    url: generateRoute(url, clinic.id),
    data: clinicParams(clinic),
  });
};

export const apiDeleteClinic = ({ clinic }) => {
  const { url, method } = DELETE_CLINIC_API;

  return axios({
    method,
    url: generateRoute(url, clinic.id),
  });
};

const clinicParams = (clinic) => {
  let params = {
    email: clinic.email,
    username: clinic.username,
    name: clinic.name,
    phone: clinic.phone,
    mobile: clinic.mobile,
    location: clinic.location,
    businessName: clinic.businessName,
    abn: clinic.abn,
    overview: clinic.overview,
    logo: clinic.logo ? { sourceId: extractIdFrom(clinic.logo.sourceId) } : null,
    staffId: extractIdFrom(clinic.staffId),
    regionalManagerId: extractIdFrom(clinic.regionalManagerId),
    voucherPrefix: clinic.voucherPrefix,
    display: true,
  }

  if (clinic.password) {
    params = {
      ...params,
      password: clinic.password,
    }
  }

  return params;
};
