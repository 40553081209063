import _ from 'lodash';
import {
  CREATE_GIFT_CARD_DECLARATION_SUCCESS,
  DELETE_GIFT_CARD_DECLARATION_SUCCESS,
  UPDATE_GIFT_CARD_DECLARATION_SUCCESS
} from "redux/model/giftCardDeclarations/giftCardDeclaration/constants";
import {
  INIT_GIFT_CARD_DECLARATIONS,
  LIST_GIFT_CARD_DECLARATIONS,
  LIST_GIFT_CARD_DECLARATIONS_FAILED,
  LIST_GIFT_CARD_DECLARATIONS_SUCCESS
} from "redux/model/giftCardDeclarations/giftCardDeclarations/constants";

const INIT_STATE = {
  giftCardDeclarations: {},
  loading: true,
  error: null,
};

const giftCardDeclarationsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_GIFT_CARD_DECLARATIONS:
      return INIT_STATE;

    case LIST_GIFT_CARD_DECLARATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_GIFT_CARD_DECLARATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        giftCardDeclarations: _.mapKeys(action.payload.giftCardDeclarations, 'id'),
      };

    case LIST_GIFT_CARD_DECLARATIONS_FAILED:
      return {
        ...state,
        giftCardDeclarations: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_GIFT_CARD_DECLARATION_SUCCESS:
      return {
        ...state,
        giftCardDeclarations: {
          [action.payload.giftCardDeclaration.id]: action.payload.giftCardDeclaration,
          ...state.giftCardDeclarations,
        },
      };

    case UPDATE_GIFT_CARD_DECLARATION_SUCCESS:
      return {
        ...state,
        giftCardDeclarations: {
          ...state.giftCardDeclarations,
          [action.payload.giftCardDeclaration.id]: action.payload.giftCardDeclaration,
        },
      };

    case DELETE_GIFT_CARD_DECLARATION_SUCCESS:
      return {
        ...state,
        giftCardDeclarations: _.omit(state.giftCardDeclarations, action.payload.giftCardDeclaration.id)
      };

    default:
      return { ...state };
  }
};

export default giftCardDeclarationsReducers;
