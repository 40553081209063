import { all } from 'redux-saga/effects';
import clinicSaga from "redux/model/clinics/clinic/clinicSaga";
import clinicsSaga from "redux/model/clinics/clinics/clinicsSaga";
import staffSaga from "redux/model/staffs/staff/staffSaga";
import staffsSaga from "redux/model/staffs/staffs/staffsSaga";
import practitionerSaga from "redux/model/practitioners/practitioner/practitionerSaga";
import practitionersSaga from "redux/model/practitioners/practitioners/practitionersSaga";
import giftCardSaga from "redux/model/giftCards/giftCard/giftCardSaga";
import giftCardsSaga from "redux/model/giftCards/giftCards/giftCardsSaga";
import logoSaga from "redux/model/general/logo/logoSaga";
import noticesSaga from "redux/model/notices/notices/noticesSaga";
import assistantReportSaga from "redux/model/assistantReports/assistantReport/assistantReportSaga";
import assistantReportsSaga from "redux/model/assistantReports/assistantReports/assistantReportsSaga";
import practitionerReportSaga from "redux/model/practitionerReports/practitionerReport/practitionerReportSaga";
import surveysSaga from "redux/model/surveys/surveys/surveysSaga";
import statisticSaga from "redux/model/statistics/statistic/statisticSaga";
import productGiftCardSaga from "redux/model/productGiftCards/productGiftCard/productGiftCardSaga";
import productGiftCardsSaga from "redux/model/productGiftCards/productGiftCards/productGiftCardsSaga";
import transactionsSaga from "redux/model/transactions/transactions/transactionsSaga";
import giftCardDeclarationSaga from "redux/model/giftCardDeclarations/giftCardDeclaration/giftCardDeclarationSaga";
import giftCardDeclarationsSaga from "redux/model/giftCardDeclarations/giftCardDeclarations/giftCardDeclarationsSaga";

function* modelSaga() {
  yield all([
    logoSaga(),
    clinicSaga(),
    clinicsSaga(),
    staffSaga(),
    staffsSaga(),
    practitionerSaga(),
    practitionersSaga(),
    giftCardSaga(),
    giftCardsSaga(),
    noticesSaga(),
    assistantReportSaga(),
    assistantReportsSaga(),
    practitionerReportSaga(),
    surveysSaga(),
    statisticSaga(),
    productGiftCardSaga(),
    productGiftCardsSaga(),
    giftCardDeclarationSaga(),
    giftCardDeclarationsSaga(),
    transactionsSaga(),
  ]);
}

export default modelSaga;
