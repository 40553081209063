import _ from 'lodash';
import {
  CREATE_ASSISTANT_REPORT_SUCCESS, UNLOCK_ASSISTANT_REPORT_SUCCESS,
  UPDATE_ASSISTANT_REPORT_SUCCESS
} from "redux/model/assistantReports/assistantReport/constants";
import {
  LIST_ASSISTANT_REPORTS,
  LIST_ASSISTANT_REPORTS_FAILED,
  LIST_ASSISTANT_REPORTS_SUCCESS, LIST_ASSISTANT_REPORTS_ADMIN_DAILY_TOTAL
} from "redux/model/assistantReports/assistantReports/constants";

const INIT_STATE = {
  assistantReports: {},
  loading: true,
  error: null,
};

const assistantReportsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_ASSISTANT_REPORTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LIST_ASSISTANT_REPORTS_ADMIN_DAILY_TOTAL:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_ASSISTANT_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        assistantReports: _.mapKeys(action.payload.assistantReports, 'id'),
      };

    case LIST_ASSISTANT_REPORTS_FAILED:
      return {
        ...state,
        assistantReports: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_ASSISTANT_REPORT_SUCCESS:
      return {
        ...state,
        assistantReports: {
          [action.payload.assistantReport.id]: action.payload.assistantReport,
          ...state.assistantReports,
        },
      };

    case UPDATE_ASSISTANT_REPORT_SUCCESS:
      return {
        ...state,
        assistantReports: {
          ...state.assistantReports,
          [action.payload.assistantReport.id]: action.payload.assistantReport,
        },
      };

    case UNLOCK_ASSISTANT_REPORT_SUCCESS:
      return {
        ...state,
        assistantReports: {
          ...state.assistantReports,
          [action.payload.assistantReport.id]: {
            ...state.assistantReports[action.payload.assistantReport.id],
            isLocked: false
          },
        },
      }

    default:
      return { ...state };
  }
};

export default assistantReportsReducer;
