import React from "react";
import { BASE_GIFT_CARD_PATH, INDEX_GIFT_CARDS_PATH, NEW_GIFT_CARD_PATH, SHOW_GIFT_CARD_PATH } from "routes/giftCard/constants";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";

const GiftCards = React.lazy(() => import('pages/giftCards/index/GiftCards'));
const NewGiftCard = React.lazy(() => import('pages/giftCards/new/NewGiftCard'));
const ShowGiftCard = React.lazy(() => import('pages/giftCards/show/ShowGiftCard'));

export const giftCardRoutes = {
  path: BASE_GIFT_CARD_PATH,
  name: 'GiftCards',
  icon: 'uil uil-gift',
  children: [
    {
      path: INDEX_GIFT_CARDS_PATH,
      exact: true,
      name: 'GiftCards',
      component: GiftCards,
      route: PrivateRoute,
    },
  ],
};

export const hiddenGiftCardRoutes = {
  children: [
    {
      path: SHOW_GIFT_CARD_PATH,
      exact: true,
      name: 'Show GiftCard',
      component: ShowGiftCard,
      route: PrivateRoute,
    }
  ]
}
