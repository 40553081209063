
export const LIST_NOTICE_API = {
  url: `/api/notifications/log/notices`,
  method: 'get',
}

export const CREATE_NOTICE_API = {
  url: `/api/notifications/message/notices`,
  method: 'post',
}
