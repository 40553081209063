import { LIST_CLINICS, LIST_CLINICS_FAILED, LIST_CLINICS_SUCCESS } from "redux/model/clinics/clinics/constants";

export const listClinics = () => ({
  type: LIST_CLINICS,
  payload: {}
});

export const listClinicsSuccess = ({ clinics }) => ({
  type: LIST_CLINICS_SUCCESS,
  payload: { clinics }
});

export const listClinicsFailed = ({ error }) => ({
  type: LIST_CLINICS_FAILED,
  payload: { error }
});
