import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  generateRoute,
  history,
  throwFrontError
} from "@tmatt-tech/allaroundmassage_frontend_common";
import { CREATE_CLINIC, DELETE_CLINIC, FETCH_CLINIC, UPDATE_CLINIC } from "redux/model/clinics/clinic/constants";
import { apiCreateClinic, apiDeleteClinic, apiFetchClinic, apiUpdateClinic } from "api/clinic/clinicApi";
import {
  createClinicFailed,
  createClinicSuccess,
  deleteClinicFailed,
  deleteClinicSuccess,
  fetchClinicFailed,
  fetchClinicSuccess,
  initClinic,
  updateClinicFailed,
  updateClinicSuccess
} from "redux/model/clinics/clinic/clinicActions";
import {
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import { closeSecondLayerModal } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { INDEX_CLINIC_PATH } from "routes/clinic/constants";


function* clinicSaga() {
  yield all([
    fork(watchFetchClinic),
    fork(watchUpdateClinic),
    fork(watchCreateClinic),
    fork(watchDeleteClinic),
  ]);
}

// Fetch Clinic
export function* watchFetchClinic() {
  yield takeEvery(FETCH_CLINIC, sagaFetchClinic);
}

export function* sagaFetchClinic({ payload }) {
  try {
    const response = yield call(apiFetchClinic, payload);

    yield put(fetchClinicSuccess({ clinic: response.data }));

  } catch (e) {
    yield put(fetchClinicFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Clinic
export function* watchCreateClinic() {
  yield takeLeading(CREATE_CLINIC, sagaCreateClinic);
}

export function* sagaCreateClinic({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateClinic, payload);

    yield put(createClinicSuccess({ clinic: response.data }));
    yield put(toastSaved({ name: response.data.name }));
    history.push(generateRoute(INDEX_CLINIC_PATH));
    yield put(initClinic());


  } catch (e) {
    yield put(createClinicFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Clinic
export function* watchUpdateClinic() {
  yield takeLeading(UPDATE_CLINIC, sagaUpdateClinic);
}

export function* sagaUpdateClinic({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateClinic, payload);

    yield put(updateClinicSuccess({ clinic: response.data }));
    yield put(toastSaved({ name: response.data.name }));
    history.push(generateRoute(INDEX_CLINIC_PATH));
    yield put(initClinic());

  } catch (e) {
    yield put(updateClinicFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Clinic
export function* watchDeleteClinic() {
  yield takeLeading(DELETE_CLINIC, sagaDeleteClinic);
}

export function* sagaDeleteClinic({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteClinic, payload);

    yield put(deleteClinicSuccess({ clinic: response.data }));
    yield put(toastDeleted({ name: response.data.name }));
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteClinicFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default clinicSaga;
