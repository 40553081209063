import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { SHOW_STATISTICS_PATH } from "routes/statistics/constants";

const ShowStatistics = React.lazy(() => import('pages/statistics/show/ShowStatistics'));

export const statisticsRoutes = {
  path: SHOW_STATISTICS_PATH,
  name: 'Statistics',
  icon: 'uil uil-user',
  component: ShowStatistics,
  route: PrivateRoute,
};

export const hiddenStatisticsRoutes = {
  children: [
  ]
}
