import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { BASE_ASSISTANT_REPORT_PATH, SHOW_ASSISTANT_REPORT_PATH } from "routes/assistantReport/constants";

const ShowAssistantReport = React.lazy(() => import('pages/assistantReports/show/ShowAssistantReport'));

export const assistantReportRoutes = {
  path: BASE_ASSISTANT_REPORT_PATH,
  name: 'Reports',
  icon: 'uil uil-schedule',
  children: [],
};

export const hiddenAssistantReportRoutes = {
  children: [
    {
      path: SHOW_ASSISTANT_REPORT_PATH,
      name: 'Show Report',
      component: ShowAssistantReport,
      route: PrivateRoute,
    }
  ]
}
