import {
  INIT_GIFT_CARD_DECLARATIONS,
  LIST_GIFT_CARD_DECLARATIONS,
  LIST_GIFT_CARD_DECLARATIONS_FAILED,
  LIST_GIFT_CARD_DECLARATIONS_SUCCESS
} from "redux/model/giftCardDeclarations/giftCardDeclarations/constants";

export const initGiftCardDeclarations = () => ({
  type: INIT_GIFT_CARD_DECLARATIONS,
  payload: {}
});

export const listGiftCardDeclarations = ({ clinic }) => ({
  type: LIST_GIFT_CARD_DECLARATIONS,
  payload: { clinic }
});

export const listGiftCardDeclarationsSuccess = ({ giftCardDeclarations }) => ({
  type: LIST_GIFT_CARD_DECLARATIONS_SUCCESS,
  payload: { giftCardDeclarations }
});

export const listGiftCardDeclarationsFailed = ({ error }) => ({
  type: LIST_GIFT_CARD_DECLARATIONS_FAILED,
  payload: { error }
});
