
export const CREATE_STAFF_API = {
  url: `/api/staffs`,
  method: 'post'
};

export const LIST_STAFF_API = {
  url: `/api/staffs`,
  method: 'get',
}

export const FETCH_STAFF_API = {
  url: `/api/staffs/:id`,
  method: 'get',
}

export const UPDATE_STAFF_API = {
  url: `/api/staffs/:id`,
  method: 'put'
}

export const DELETE_STAFF_API = {
  url: `/api/staffs/:id`,
  method: 'delete'
}

export const FETCH_CURRENT_STAFF_API = {
  url: `/api/staff/current`,
  method: 'get',
}
