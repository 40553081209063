
export const CREATE_GIFT_CARD_API = {
  url: `/api/system/gift_cards`,
  method: 'post'
};

export const LIST_GIFT_CARD_API = {
  url: `/api/system/gift_cards`,
  method: 'get',
}

export const FETCH_GIFT_CARD_API = {
  url: `/api/system/gift_cards/:id`,
  method: 'get',
}

export const UPDATE_GIFT_CARD_API = {
  url: `/api/system/gift_cards/:id`,
  method: 'put'
}

export const DELETE_GIFT_CARD_API = {
  url: `/api/system/gift_cards/:id`,
  method: 'delete'
}

export const SYNC_SHOPIFY_GIFT_CARDS_API = {
  url: `/api/system/gift_cards/sync_shopify`,
  method: 'post'
};

export const LIST_GIFT_CARD_TODAY_API = {
  url: `/api/system/gift_cards/index_today`,
  method: 'post',
}