import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  DOWNLOAD_ASSISTANT_REPORT_PRACTITIONER_SUMMARY,
  DOWNLOAD_ASSISTANT_REPORT_SUMMARY,
  LIST_ASSISTANT_REPORTS,
  LIST_ASSISTANT_REPORTS_ADMIN_DAILY_TOTAL
} from "redux/model/assistantReports/assistantReports/constants";
import {
  apiAssistantReportAdminDailyTotal, apiDownloadAssistantReportPractitionerSummary,
  apiDownloadAssistantReportSummary,
  apiListAssistantReports
} from "api/assistantReport/assistantReportApi";
import {
  listAssistantReportsFailed,
  listAssistantReportsSuccess
} from "redux/model/assistantReports/assistantReports/assistantReportsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";
import fileDownload from "js-file-download";


function* assistantReportsSaga() {
  yield all([
    fork(watchListAssistantReports),
    fork(watchListAssistantReportsAdminDailyTotal),
    fork(watchDownloadAssistantReportSummary),
    fork(watchDownloadAssistantReportPractitionerSummary),
  ]);
}

// List AssistantReports
export function* watchListAssistantReports() {
  yield takeEvery(LIST_ASSISTANT_REPORTS, sagaListAssistantReports);
}

export function* sagaListAssistantReports({ payload }) {
  try {
    const response = yield call(apiListAssistantReports, payload);

    yield put(listAssistantReportsSuccess({ assistantReports: response.data }));

  } catch (e) {
    yield put(listAssistantReportsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// List AssistantReports Today Total
export function* watchListAssistantReportsAdminDailyTotal() {
  yield takeEvery(LIST_ASSISTANT_REPORTS_ADMIN_DAILY_TOTAL, sagaListAssistantReportsAdminDailyTotal);
}

export function* sagaListAssistantReportsAdminDailyTotal({ payload }) {
  try {
    const response = yield call(apiAssistantReportAdminDailyTotal, payload);

    yield put(listAssistantReportsSuccess({ assistantReports: response.data.assistantReports }));

  } catch (e) {
    yield put(listAssistantReportsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Download AssistantReports Summary
export function* watchDownloadAssistantReportSummary() {
  yield takeEvery(DOWNLOAD_ASSISTANT_REPORT_SUMMARY, sagaDownloadAssistantReportSummary);
}

export function* sagaDownloadAssistantReportSummary({ payload }) {
  try {
    const response = yield call(apiDownloadAssistantReportSummary, payload);
    const { from, to, clinic } = payload;

    yield call(fileDownload, response.data.csv, `${ clinic.name }_${ from }_${ to }.csv`);

  } catch (e) {
    throwFrontError(e);
  }
}

// Download AssistantReports Practitioner Summary
export function* watchDownloadAssistantReportPractitionerSummary() {
  yield takeEvery(DOWNLOAD_ASSISTANT_REPORT_PRACTITIONER_SUMMARY, sagaDownloadAssistantReportPractitionerSummary);
}

export function* sagaDownloadAssistantReportPractitionerSummary({ payload }) {
  try {
    const response = yield call(apiDownloadAssistantReportPractitionerSummary, payload);
    const { from, to } = payload;

    yield call(fileDownload, response.data.csv, `practitioners_${ from }_${ to }.csv`);

  } catch (e) {
    throwFrontError(e);
  }
}


export default assistantReportsSaga;
