import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  throwFrontError,
  history,
  generateRoute
} from "@tmatt-tech/allaroundmassage_frontend_common";
import { CREATE_GIFT_CARD, DELETE_GIFT_CARD, FETCH_GIFT_CARD, UPDATE_GIFT_CARD } from "redux/model/giftCards/giftCard/constants";
import { apiCreateGiftCard, apiDeleteGiftCard, apiFetchGiftCard, apiUpdateGiftCard } from "api/giftCard/giftCardApi";
import {
  createGiftCardFailed,
  createGiftCardSuccess, deleteGiftCardFailed, deleteGiftCardSuccess,
  fetchGiftCardFailed,
  fetchGiftCardSuccess, initGiftCard,
  updateGiftCardFailed,
  updateGiftCardSuccess
} from "redux/model/giftCards/giftCard/giftCardActions";
import {
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { INDEX_GIFT_CARDS_PATH } from "routes/giftCard/constants";
import { listTransactionsSuccess } from "redux/model/transactions/transactions/transactionsActions";



function* giftCardSaga() {
  yield all([
    fork(watchFetchGiftCard),
    fork(watchUpdateGiftCard),
    fork(watchCreateGiftCard),
    fork(watchDeleteGiftCard),
  ]);
}

// Fetch GiftCard
export function* watchFetchGiftCard() {
  yield takeEvery(FETCH_GIFT_CARD, sagaFetchGiftCard);
}

export function* sagaFetchGiftCard({ payload }) {
  try {
    const response = yield call(apiFetchGiftCard, payload);

    const { giftCard, transactions } = response.data;
    yield put(fetchGiftCardSuccess({ giftCard }));
    yield put(listTransactionsSuccess({ transactions }));

  } catch (e) {
    yield put(fetchGiftCardFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create GiftCard
export function* watchCreateGiftCard() {
  yield takeLeading(CREATE_GIFT_CARD, sagaCreateGiftCard);
}

export function* sagaCreateGiftCard({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateGiftCard, payload);

    const { giftCard } = response.data;
    yield put(createGiftCardSuccess({ giftCard }));
    yield put(toastSaved({ name: `GiftCard` }));


    history.push(generateRoute(INDEX_GIFT_CARDS_PATH));
    yield put(initGiftCard());


  } catch (e) {
    yield put(createGiftCardFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update GiftCard
export function* watchUpdateGiftCard() {
  yield takeLeading(UPDATE_GIFT_CARD, sagaUpdateGiftCard);
}

export function* sagaUpdateGiftCard({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateGiftCard, payload);

    const { giftCard } = response.data;
    yield put(updateGiftCardSuccess({ giftCard }));
    yield put(toastSaved({ name: 'GiftCard' }));
    history.push(generateRoute(INDEX_GIFT_CARDS_PATH));
    yield put(initGiftCard());

  } catch (e) {
    yield put(updateGiftCardFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete GiftCard
export function* watchDeleteGiftCard() {
  yield takeLeading(DELETE_GIFT_CARD, sagaDeleteGiftCard);
}

export function* sagaDeleteGiftCard({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteGiftCard, payload);

    const { giftCard } = response.data;
    yield put(deleteGiftCardSuccess({ giftCard }));
    yield put(toastDeleted({ name: 'GiftCard' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteGiftCardFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default giftCardSaga;
