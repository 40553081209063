
export const CREATE_PRODUCT_GIFT_CARD_API = {
  url: `/api/system/product/gift_cards`,
  method: 'post'
};

export const INDEX_PRODUCT_GIFT_CARDS_API = {
  url: `/api/system/product/gift_cards`,
  method: 'get',
};

export const FETCH_PRODUCT_GIFT_CARD_API = {
  url: `/api/system/product/gift_cards/:id`,
  method: 'get',
};

export const UPDATE_PRODUCT_GIFT_CARD_API = {
  url: `/api/system/product/gift_cards/:id`,
  method: 'put'
};

export const DELETE_PRODUCT_GIFT_CARD_API = {
  url: `/api/system/product/gift_cards/:id`,
  method: 'delete'
};

export const SYNC_SHOPIFY_PRODUCT_GIFT_CARDS_API = {
  url: `/api/system/product/gift_cards/sync_shopify`,
  method: 'post'
};