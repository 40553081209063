import {
  FETCH_STATISTIC,
  FETCH_STATISTIC_FAILED,
  FETCH_STATISTIC_SUCCESS,
  INIT_STATISTIC
} from "redux/model/statistics/statistic/constants";

export const initStatistic = () => ({
  type: INIT_STATISTIC,
  payload: {}
});

export const fetchStatistic = () => ({
  type: FETCH_STATISTIC,
  payload: {}
});

export const fetchStatisticSuccess = ({ statistic }) => ({
  type: FETCH_STATISTIC_SUCCESS,
  payload: { statistic }
});

export const fetchStatisticFailed = ({ error }) => ({
  type: FETCH_STATISTIC_FAILED,
  payload: { error }
});