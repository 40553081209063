import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_GIFT_CARD_DECLARATIONS } from "redux/model/giftCardDeclarations/giftCardDeclarations/constants";
import { apiListGiftCardDeclarations } from "api/giftCardDeclaration/apiGiftCardDeclaration";
import {
  listGiftCardDeclarationsFailed,
  listGiftCardDeclarationsSuccess
} from "redux/model/giftCardDeclarations/giftCardDeclarations/giftCardDeclarationsActions";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";


function* giftCardDeclarationsSaga() {
  yield all([
    fork(watchListGiftCardDeclarations),
  ]);
}

// List GiftCardDeclarations
export function* watchListGiftCardDeclarations() {
  yield takeEvery(LIST_GIFT_CARD_DECLARATIONS, sagaListGiftCardDeclarations);
}

export function* sagaListGiftCardDeclarations({ payload }) {
  try {
    const response = yield call(apiListGiftCardDeclarations, payload);

    const { giftCardDeclarations } = response.data;

    yield put(listGiftCardDeclarationsSuccess({ giftCardDeclarations }));

  } catch (e) {
    yield put(listGiftCardDeclarationsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default giftCardDeclarationsSaga;
