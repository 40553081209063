import {
  CREATE_LOGO,
  CREATE_LOGO_FAILED,
  CREATE_LOGO_SUCCESS,
  UPDATE_LOGO, UPDATE_LOGO_FAILED,
  UPDATE_LOGO_SUCCESS
} from "redux/model/general/logo/constants";

export const createLogo = ({ logo, service }) => ({
  type: CREATE_LOGO,
  payload: { logo, service }
});

export const createLogoSuccess = ({ logo }) => ({
  type: CREATE_LOGO_SUCCESS,
  payload: { logo }
});

export const createLogoFailed = ({ error }) => ({
  type: CREATE_LOGO_FAILED,
  payload: { error }
});

export const updateLogo = ({ logo, service }) => ({
  type: UPDATE_LOGO,
  payload: { logo, service }
});

export const updateLogoSuccess = ({ logo }) => ({
  type: UPDATE_LOGO_SUCCESS,
  payload: { logo }
});

export const updateLogoFailed = ({ error }) => ({
  type: UPDATE_LOGO_FAILED,
  payload: { error }
});
