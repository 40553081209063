// @flow
import React, { Component } from 'react';
import Routes from './routes/Routes';
import { ConfigToast } from "@tmatt-tech/allaroundmassage_frontend_common";
import 'react-toastify/dist/ReactToastify.css';


// Themes
import '@tmatt-tech/allaroundmassage_frontend_common/dist/assets/scss/Saas.scss';
import ConfigTheme from "configs/components/ConfigTheme";
// For Dark import Saas-Dark.scss
// import '@tmatt-tech/allaroundmassage_frontend_common/dist/assets/scss/Saas-Dark.scss';

// // For Modern import Modern.scss
// import '@tmatt-tech/allaroundmassage_frontend_common/dist/assets/scss/Modern.scss';
// // For modern dakr import Modern-Dark.scss
// import '@tmatt-tech/allaroundmassage_frontend_common/dist/assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import '@tmatt-tech/allaroundmassage_frontend_common/dist/assets/scss/Creative.scss';
// // For Creative dark demo import Modern.scss
// import '@tmatt-tech/allaroundmassage_frontend_common/dist/assets/scss/Creative-Dark.scss';


type AppProps = {};

/**
 * Main app component
 */
class App extends Component<AppProps> {
  render() {
    return (
      <React.Fragment>
        <ConfigTheme />
        <ConfigToast />

        <Routes />
      </React.Fragment>
    );
  }
}

export default App;
