import { combineReducers } from 'redux';
import formClinicReducers from "redux/ui/form/clinic/formClinicReducers";
import formPractitionerReducers from "redux/ui/form/practitioner/formPractitionerReducers";
import formGiftCardReducers from "redux/ui/form/giftCard/formGiftCardReducers";
import formStaffReducers from "redux/ui/form/staff/formStaffReducers";
import formImageEditorReducers from "redux/ui/form/imageEditor/formImageEditorReducers";
import formPractitionerReportReducers from "redux/ui/form/practitionerReport/formPractitionerReportReducers";
import formProductGiftCardReducers from "redux/ui/form/productGiftCard/formProductGiftCardReducers";
import formGiftCardDeclarationReducers from "redux/ui/form/giftCardDeclaration/formGiftCardDeclarationReducers";

export default combineReducers({
  clinic: formClinicReducers,
  practitioner: formPractitionerReducers,
  giftCard: formGiftCardReducers,
  staff: formStaffReducers,
  imageEditor: formImageEditorReducers,
  practitionerReport: formPractitionerReportReducers,
  productGiftCard: formProductGiftCardReducers,
  giftCardDeclaration: formGiftCardDeclarationReducers,
});
