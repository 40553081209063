import {
  ASSIGN_CLINIC_ABN,
  ASSIGN_CLINIC_BUSINESS_NAME,
  ASSIGN_CLINIC_EMAIL,
  ASSIGN_CLINIC_LOCATION, ASSIGN_CLINIC_LOGO,
  ASSIGN_CLINIC_MOBILE,
  ASSIGN_CLINIC_NAME,
  ASSIGN_CLINIC_OVERVIEW,
  ASSIGN_CLINIC_PASSWORD,
  ASSIGN_CLINIC_PHONE,
  ASSIGN_CLINIC_USERNAME,
  ASSIGN_CLINIC_SHOP_MANAGER,
  ASSIGN_CLINIC_REGIONAL_MANAGER,
  CREATE_CLINIC,
  CREATE_CLINIC_FAILED,
  CREATE_CLINIC_SUCCESS,
  DELETE_CLINIC,
  DELETE_CLINIC_FAILED,
  DELETE_CLINIC_SUCCESS,
  FETCH_CLINIC,
  FETCH_CLINIC_FAILED,
  FETCH_CLINIC_SUCCESS,
  INIT_CLINIC,
  UPDATE_CLINIC,
  UPDATE_CLINIC_FAILED,
  UPDATE_CLINIC_SUCCESS, ASSIGN_CLINIC_VOUCHER_PREFIX
} from "redux/model/clinics/clinic/constants";

export const initClinic = () => ({
  type: INIT_CLINIC,
  payload: {}
});

export const createClinic = ({ clinic }) => ({
  type: CREATE_CLINIC,
  payload: { clinic }
});

export const createClinicSuccess = ({ clinic }) => ({
  type: CREATE_CLINIC_SUCCESS,
  payload: { clinic }
});

export const createClinicFailed = ({ error }) => ({
  type: CREATE_CLINIC_FAILED,
  payload: { error }
});

export const fetchClinic = ({ clinicId }) => ({
  type: FETCH_CLINIC,
  payload: { clinicId }
});

export const fetchClinicSuccess = ({ clinic }) => ({
  type: FETCH_CLINIC_SUCCESS,
  payload: { clinic }
});

export const fetchClinicFailed = ({ error }) => ({
  type: FETCH_CLINIC_FAILED,
  payload: { error }
});

export const updateClinic = ({ clinic }) => ({
  type: UPDATE_CLINIC,
  payload: { clinic }
});

export const updateClinicSuccess = ({ clinic }) => ({
  type: UPDATE_CLINIC_SUCCESS,
  payload: { clinic }
});

export const updateClinicFailed = ({ error }) => ({
  type: UPDATE_CLINIC_FAILED,
  payload: { error }
});

export const deleteClinic = ({ clinic }) => ({
  type: DELETE_CLINIC,
  payload: { clinic }
});

export const deleteClinicSuccess = ({ clinic }) => ({
  type: DELETE_CLINIC_SUCCESS,
  payload: { clinic }
});

export const deleteClinicFailed = ({ error }) => ({
  type: DELETE_CLINIC_FAILED,
  payload: { error }
});

export const assignClinicEmail = ({ email }) => ({
  type: ASSIGN_CLINIC_EMAIL,
  payload: { email }
});

export const assignClinicUsername = ({ username }) => ({
  type: ASSIGN_CLINIC_USERNAME,
  payload: { username }
});

export const assignClinicShopManager = ({ staff }) => ({
  type: ASSIGN_CLINIC_SHOP_MANAGER,
  payload: { staff }
});

export const assignClinicRegionalManager = ({ regionalManager }) => ({
  type: ASSIGN_CLINIC_REGIONAL_MANAGER,
  payload: { regionalManager }
});

export const assignClinicPassword = ({ password }) => ({
  type: ASSIGN_CLINIC_PASSWORD,
  payload: { password }
});

export const assignClinicName = ({ name }) => ({
  type: ASSIGN_CLINIC_NAME,
  payload: { name }
});

export const assignClinicPhone = ({ phone }) => ({
  type: ASSIGN_CLINIC_PHONE,
  payload: { phone }
});

export const assignClinicMobile = ({ mobile }) => ({
  type: ASSIGN_CLINIC_MOBILE,
  payload: { mobile }
});

export const assignClinicLocation = ({ location }) => ({
  type: ASSIGN_CLINIC_LOCATION,
  payload: { location }
});

export const assignClinicBusinessName = ({ businessName }) => ({
  type: ASSIGN_CLINIC_BUSINESS_NAME,
  payload: { businessName }
});

export const assignClinicAbn = ({ abn }) => ({
  type: ASSIGN_CLINIC_ABN,
  payload: { abn }
});

export const assignClinicOverview = ({ overview }) => ({
  type: ASSIGN_CLINIC_OVERVIEW,
  payload: { overview }
});

export const assignClinicLogo = ({ logo }) => ({
  type: ASSIGN_CLINIC_LOGO,
  payload: { logo }
});

export const assignClinicVoucherPrefix = ({ voucherPrefix }) => ({
  type: ASSIGN_CLINIC_VOUCHER_PREFIX,
  payload: { voucherPrefix }
});