import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import { BASE_STAFF_PATH, INDEX_STAFF_PATH, NEW_STAFF_PATH, EDIT_STAFF_PATH } from "routes/staff/constants";

const AllStaff = React.lazy(() => import('pages/staffs/all/Staffs'));
const NewStaff = React.lazy(() => import('pages/staffs/new/NewStaff'));
const EditStaff = React.lazy(() => import('pages/staffs/edit/EditStaff'));

export const staffRoutes = {
  path: BASE_STAFF_PATH,
  name: 'Staffs',
  icon: 'uil uil-user',
  children: [
    {
      path: INDEX_STAFF_PATH,
      name: 'All Staffs',
      component: AllStaff,
      route: PrivateRoute,
    },
    {
      path: NEW_STAFF_PATH,
      name: 'Add New',
      component: NewStaff,
      route: PrivateRoute,
    },
  ],
};

export const hiddenStaffRoutes = {
  children: [
    {
      path: EDIT_STAFF_PATH,
      name: 'Edit Staff',
      component: EditStaff,
      route: PrivateRoute,
    }
  ]
}
