import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  throwFrontError,
  history,
  generateRoute, LOGOUT_PATH
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_STAFF,
  DELETE_STAFF,
  FETCH_CURRENT_STAFF,
  FETCH_STAFF,
  UPDATE_STAFF
} from "redux/model/staffs/staff/constants";
import {
  apiCreateStaff,
  apiDeleteStaff,
  apiFetchCurrentStaff,
  apiFetchStaff,
  apiUpdateStaff
} from "api/staff/staffApi";
import {
  createStaffFailed,
  createStaffSuccess, deleteStaffFailed, deleteStaffSuccess, fetchCurrentStaffFailed, fetchCurrentStaffSuccess,
  fetchStaffFailed,
  fetchStaffSuccess, initStaff,
  updateStaffFailed,
  updateStaffSuccess
} from "redux/model/staffs/staff/staffActions";
import {
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import { closeSecondLayerModal } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { INDEX_STAFF_PATH } from "routes/staff/constants";
import { logoutUser } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/auth/authActions";


function* staffSaga() {
  yield all([
    fork(watchFetchStaff),
    fork(watchUpdateStaff),
    fork(watchCreateStaff),
    fork(watchDeleteStaff),
    fork(watchFetchCurrentStaff),
  ]);
}

// Fetch Staff
export function* watchFetchStaff() {
  yield takeEvery(FETCH_STAFF, sagaFetchStaff);
}

export function* sagaFetchStaff({ payload }) {
  try {
    const response = yield call(apiFetchStaff, payload);

    yield put(fetchStaffSuccess({ staff: response.data }));

  } catch (e) {
    yield put(fetchStaffFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch Current Staff
export function* watchFetchCurrentStaff() {
  yield takeEvery(FETCH_CURRENT_STAFF, sagaFetchCurrentStaff);
}

export function* sagaFetchCurrentStaff({ payload }) {
  try {
    const response = yield call(apiFetchCurrentStaff);

    const { role } = response.data;

    if (role === 'Reception') {
      history.push(LOGOUT_PATH);

    } else {
      yield put(fetchCurrentStaffSuccess({ staff: response.data }));
    }


  } catch (e) {
    yield put(fetchCurrentStaffFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Staff
export function* watchCreateStaff() {
  yield takeLeading(CREATE_STAFF, sagaCreateStaff);
}

export function* sagaCreateStaff({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateStaff, payload);

    yield put(createStaffSuccess({ staff: response.data }));
    yield put(toastSaved({ name: response.data.email }));
    history.push(generateRoute(INDEX_STAFF_PATH));
    yield put(initStaff());


  } catch (e) {
    yield put(createStaffFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Staff
export function* watchUpdateStaff() {
  yield takeLeading(UPDATE_STAFF, sagaUpdateStaff);
}

export function* sagaUpdateStaff({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateStaff, payload);

    yield put(updateStaffSuccess({ staff: response.data }));
    yield put(toastSaved({ name: response.data.email }));
    history.push(generateRoute(INDEX_STAFF_PATH));
    yield put(initStaff());

  } catch (e) {
    yield put(updateStaffFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Staff
export function* watchDeleteStaff() {
  yield takeLeading(DELETE_STAFF, sagaDeleteStaff);
}

export function* sagaDeleteStaff({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteStaff, payload);

    yield put(deleteStaffSuccess({ staff: response.data }));
    yield put(toastDeleted({ name: response.data.email }));
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteStaffFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default staffSaga;
