import {
  FETCH_STATISTIC,
  FETCH_STATISTIC_FAILED,
  FETCH_STATISTIC_SUCCESS,
  INIT_STATISTIC
} from "redux/model/statistics/statistic/constants";

const INIT_STATE = {
  statistic: [],
  loading: true,
  error: null,
};

const statisticReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_STATISTIC:
      return INIT_STATE;

    case FETCH_STATISTIC:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_STATISTIC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        statistic: action.payload.statistic,
      };

    case FETCH_STATISTIC_FAILED:
      return {
        ...state,
        statistic: [],
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default statisticReducer;
