import _ from 'lodash';
import {
  CREATE_CLINIC_SUCCESS,
  DELETE_CLINIC_SUCCESS,
  UPDATE_CLINIC_SUCCESS
} from "redux/model/clinics/clinic/constants";
import { LIST_CLINICS, LIST_CLINICS_FAILED, LIST_CLINICS_SUCCESS } from "redux/model/clinics/clinics/constants";

const INIT_STATE = {
  clinics: {},
  loading: true,
  error: null,
};

const clinicsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_CLINICS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_CLINICS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clinics: _.mapKeys(action.payload.clinics, 'id'),
      };

    case LIST_CLINICS_FAILED:
      return {
        ...state,
        clinics: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_CLINIC_SUCCESS:
      return {
        ...state,
        clinics: {
          [action.payload.clinic.id]: action.payload.clinic,
          ...state.clinics,
        },
      };

    case UPDATE_CLINIC_SUCCESS:
      return {
        ...state,
        clinics: {
          ...state.clinics,
          [action.payload.clinic.id]: action.payload.clinic,
        },
      };

    case DELETE_CLINIC_SUCCESS:
      return {
        ...state,
        clinics: _.omit(state.clinics, action.payload.clinic.id)
      };

    default:
      return { ...state };
  }
};

export default clinicsReducer;
