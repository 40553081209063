import {
  ASSIGN_STAFF_EMAIL,
  ASSIGN_STAFF_GENDER,
  ASSIGN_STAFF_MOBILE,
  ASSIGN_STAFF_USERNAME,
  ASSIGN_STAFF_PASSWORD,
  ASSIGN_STAFF_FIRSTNAME,
  ASSIGN_STAFF_LASTNAME,
  ASSIGN_STAFF_ROLE,
  FETCH_STAFF,
  FETCH_STAFF_FAILED,
  FETCH_STAFF_SUCCESS,
  INIT_STAFF, FETCH_CURRENT_STAFF_FAILED, FETCH_CURRENT_STAFF_SUCCESS
} from "redux/model/staffs/staff/constants";

const INIT_STATE = {
  staff: {},
  currentStaff: {},
  loading: true,
  error: null,
};

const staffReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_STAFF:
      return {
        ...state,
        staff: {},
        loading: true,
        error: null,
      };

    case FETCH_STAFF:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        staff: action.payload.staff,
      };

    case FETCH_STAFF_FAILED:
      return {
        ...state,
        staff: {},
        loading: false,
        error: action.payload.error,
      };

    case FETCH_CURRENT_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentStaff: action.payload.staff,
      };

    case FETCH_CURRENT_STAFF_FAILED:
      return {
        ...state,
        staff: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_STAFF_EMAIL:
      return {
        ...state,
        staff: {
          ...state.staff,
          email: action.payload.email,
        },
      };

    case ASSIGN_STAFF_USERNAME:
      return {
        ...state,
        staff: {
          ...state.staff,
          username: action.payload.username,
        },
      };

    case ASSIGN_STAFF_PASSWORD:
      return {
        ...state,
        staff: {
          ...state.staff,
          password: action.payload.password,
        },
      };

    case ASSIGN_STAFF_FIRSTNAME:
      return {
        ...state,
        staff: {
          ...state.staff,
          firstName: action.payload.firstName,
        },
      };

    case ASSIGN_STAFF_LASTNAME:
      return {
        ...state,
        staff: {
          ...state.staff,
          lastName: action.payload.lastName,
        },
      };

    case ASSIGN_STAFF_MOBILE:
      return {
        ...state,
        staff: {
          ...state.staff,
          mobile: action.payload.mobile,
        },
      };

    case ASSIGN_STAFF_GENDER:
      return {
        ...state,
        staff: {
          ...state.staff,
          gender: action.payload.gender,
        },
      };

    case ASSIGN_STAFF_ROLE:
      return {
        ...state,
        staff: {
          ...state.staff,
          role: action.payload.role,
        },
      };

    default:
      return { ...state };
  }
};

export default staffReducer;
