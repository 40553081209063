import {
  ASSIGN_PRACTITIONER_EMAIL,
  ASSIGN_PRACTITIONER_COMMISSION,
  ASSIGN_PRACTITIONER_FIRSTNAME,
  ASSIGN_PRACTITIONER_LASTNAME,
  ASSIGN_PRACTITIONER_MOBILE,
  FETCH_PRACTITIONER,
  FETCH_PRACTITIONER_FAILED,
  FETCH_PRACTITIONER_SUCCESS,
  INIT_PRACTITIONER
} from "redux/model/practitioners/practitioner/constants";

const INIT_STATE = {
  practitioner: {},
  loading: true,
  error: null,
};

const practitionerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PRACTITIONER:
      return INIT_STATE;

    case FETCH_PRACTITIONER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PRACTITIONER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        practitioner: action.payload.practitioner,
      };

    case FETCH_PRACTITIONER_FAILED:
      return {
        ...state,
        practitioner: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_PRACTITIONER_EMAIL:
      return {
        ...state,
        practitioner: {
          ...state.practitioner,
          email: action.payload.email,
        },
      };

    case ASSIGN_PRACTITIONER_COMMISSION:
      return {
        ...state,
        practitioner: {
          ...state.practitioner,
          email: action.payload.commission,
        },
      };

    case ASSIGN_PRACTITIONER_FIRSTNAME:
      return {
        ...state,
        practitioner: {
          ...state.practitioner,
          email: action.payload.firstName,
        },
      };

    case ASSIGN_PRACTITIONER_LASTNAME:
      return {
        ...state,
        practitioner: {
          ...state.practitioner,
          email: action.payload.lastName,
        },
      };

    case ASSIGN_PRACTITIONER_MOBILE:
      return {
        ...state,
        practitioner: {
          ...state.practitioner,
          email: action.payload.mobile,
        },
      };

    default:
      return { ...state };
  }
};

export default practitionerReducer;
