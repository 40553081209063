import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";
import { toastError } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { apiAssistantReportStatistics } from "api/assistantReport/assistantReportApi";
import { fetchStatisticFailed, fetchStatisticSuccess } from "redux/model/statistics/statistic/statisticActions";
import { FETCH_STATISTIC } from "redux/model/statistics/statistic/constants";


function* statisticSaga() {
  yield all([
    fork(watchFetchStatistic),
  ]);
}

// Fetch Statistic
export function* watchFetchStatistic() {
  yield takeEvery(FETCH_STATISTIC, sagaFetchStatistic);
}

export function* sagaFetchStatistic({ payload }) {
  try {
    const response = yield call(apiAssistantReportStatistics, payload);

    const { statistic } = response.data;
    yield put(fetchStatisticSuccess({ statistic }));

  } catch (e) {
    yield put(fetchStatisticFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

export default statisticSaga;
