export const INIT_PRACTITIONER = 'INIT_PRACTITIONER';

export const CREATE_PRACTITIONER = 'CREATE_PRACTITIONER';
export const CREATE_PRACTITIONER_SUCCESS = 'CREATE_PRACTITIONER_SUCCESS';
export const CREATE_PRACTITIONER_FAILED = 'CREATE_PRACTITIONER_FAILED';

export const FETCH_PRACTITIONER = 'FETCH_PRACTITIONER';
export const FETCH_PRACTITIONER_SUCCESS = 'FETCH_PRACTITIONER_SUCCESS';
export const FETCH_PRACTITIONER_FAILED = 'FETCH_PRACTITIONER_FAILED';

export const UPDATE_PRACTITIONER = 'UPDATE_PRACTITIONER';
export const UPDATE_PRACTITIONER_SUCCESS = 'UPDATE_PRACTITIONER_SUCCESS';
export const UPDATE_PRACTITIONER_FAILED = 'UPDATE_PRACTITIONER_FAILED';

export const DELETE_PRACTITIONER = 'DELETE_PRACTITIONER';
export const DELETE_PRACTITIONER_SUCCESS = 'DELETE_PRACTITIONER_SUCCESS';
export const DELETE_PRACTITIONER_FAILED = 'DELETE_PRACTITIONER_FAILED';

export const ASSIGN_PRACTITIONER_EMAIL = 'ASSIGN_PRACTITIONER_EMAIL';
export const ASSIGN_PRACTITIONER_USERNAME = 'ASSIGN_PRACTITIONER_USERNAME';
export const ASSIGN_PRACTITIONER_PASSWORD = 'ASSIGN_PRACTITIONER_PASSWORD';
export const ASSIGN_PRACTITIONER_FIRSTNAME = 'ASSIGN_PRACTITIONER_FIRSTNAME';
export const ASSIGN_PRACTITIONER_LASTNAME = 'ASSIGN_PRACTITIONER_LASTNAME';
export const ASSIGN_PRACTITIONER_MOBILE = 'ASSIGN_PRACTITIONER_MOBILE';
export const ASSIGN_PRACTITIONER_STATE = 'ASSIGN_PRACTITIONER_STATE';
export const ASSIGN_PRACTITIONER_COMMISSION = 'ASSIGN_PRACTITIONER_COMMISSION';
