import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_STAFFS } from "redux/model/staffs/staffs/constants";
import { apiListStaffs } from "api/staff/staffApi";
import { listStaffsFailed, listStaffsSuccess } from "redux/model/staffs/staffs/staffsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";


function* staffsSaga() {
  yield all([
    fork(watchListStaffs),
  ]);
}

// List Staffs
export function* watchListStaffs() {
  yield takeEvery(LIST_STAFFS, sagaListStaffs);
}

export function* sagaListStaffs() {
  try {
    const response = yield call(apiListStaffs);

    yield put(listStaffsSuccess({ staffs: response.data }));

  } catch (e) {
    yield put(listStaffsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default staffsSaga;
