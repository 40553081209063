import {
  ERROR_FORM_IMAGE_EDITOR,
  LOADING_FORM_IMAGE_EDITOR,
  RESET_FORM_IMAGE_EDITOR
} from "redux/ui/form/imageEditor/constants";
import {
  CREATE_LOGO,
  CREATE_LOGO_FAILED,
  CREATE_LOGO_SUCCESS,
  UPDATE_LOGO, UPDATE_LOGO_FAILED,
  UPDATE_LOGO_SUCCESS
} from "redux/model/general/logo/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formImageEditorReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_IMAGE_EDITOR:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_IMAGE_EDITOR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_IMAGE_EDITOR:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_LOGO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_LOGO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_LOGO:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_LOGO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formImageEditorReducer;
