import React from "react";
import { PrivateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  OVERVIEW_DASHBOARD_PATH,
} from "routes/dashboard/constants";

// dashboard
const Overview = React.lazy(() => import('pages/dashboards/Ecommerce'));

// dashboards
export const dashboardRoutes = {
  // Over view
  name: 'Dashboards',
  icon: 'uil-home-alt',
  header: "Dashboard",
  path: OVERVIEW_DASHBOARD_PATH,
  component: Overview,
  route: PrivateRoute,
};

export const hiddenDashboardRoutes = {
  children: []
}
