import {
  CREATE_GIFT_CARD_DECLARATION_API,
  DELETE_GIFT_CARD_DECLARATION_API,
  FETCH_GIFT_CARD_DECLARATION_API,
  INDEX_GIFT_CARD_DECLARATIONS_API,
  UPDATE_GIFT_CARD_DECLARATION_API
} from "api/giftCardDeclaration/constants";
import { generateRoute } from "helpers/routesUtils";
import { axios } from "@tmatt-tech/allaroundmassage_frontend_common";
import { extractIdFrom } from "helpers/stringUtils";

export const apiListGiftCardDeclarations = ({ clinic }) => {
  const { url, method } = INDEX_GIFT_CARD_DECLARATIONS_API;

  return axios({
    method,
    url,
    params: {
      clinicSystemId: clinic.systemId,
    }
  });
};

export const apiCreateGiftCardDeclaration = ({ giftCardDeclaration }) => {
  const { url, method } = CREATE_GIFT_CARD_DECLARATION_API;

  return axios({
    method,
    url,
    data: giftCardDeclarationParams({ giftCardDeclaration }),
  });
};

export const apiFetchGiftCardDeclaration = ({ giftCardDeclarationId }) => {
  const { url, method } = FETCH_GIFT_CARD_DECLARATION_API;

  return axios({
    method,
    url: generateRoute(url, giftCardDeclarationId),
  });
};

export const apiUpdateGiftCardDeclaration = ({ giftCardDeclaration }) => {
  const { url, method } = UPDATE_GIFT_CARD_DECLARATION_API;

  return axios({
    method,
    url: generateRoute(url, giftCardDeclaration.id),
    data: giftCardDeclarationParams({ giftCardDeclaration }),
  });
};

export const apiDeleteGiftCardDeclaration = ({ giftCardDeclaration }) => {
  const { url, method } = DELETE_GIFT_CARD_DECLARATION_API;

  return axios({
    method,
    url: generateRoute(url, giftCardDeclaration.id),
  });
};

const giftCardDeclarationParams = ({ giftCardDeclaration }) => {
  const {
    clinicSystemId,
    from,
    to,
  } = giftCardDeclaration;

  return {
    giftCardDeclaration: {
      clinicSystemId: extractIdFrom(clinicSystemId),
      from,
      to,
    }
  }
};
