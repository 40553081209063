import {
  FETCH_ASSISTANT_REPORT,
  FETCH_ASSISTANT_REPORT_FAILED,
  FETCH_ASSISTANT_REPORT_SUCCESS,
  INIT_ASSISTANT_REPORT,
  UPDATE_ASSISTANT_REPORT_SUCCESS
} from "redux/model/assistantReports/assistantReport/constants";
import { ASSIGN_PRACTITIONER_REPORT_ITEM_APPOINTMENT_TIME } from "redux/model/practitionerReportItems/practitionerReportItems/constants";
import { CREATE_PRACTITIONER_REPORT_SUCCESS } from "redux/model/practitionerReports/practitionerReport/constants";

const INIT_STATE = {
  assistantReport: {
    date: new Date(),
  },
  loading: true,
  error: null,
};

const assistantReportReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_ASSISTANT_REPORT:
      return INIT_STATE;

    case FETCH_ASSISTANT_REPORT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ASSISTANT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        assistantReport: action.payload.current.assistantReport,
      };

    case FETCH_ASSISTANT_REPORT_FAILED:
      return {
        ...state,
        assistantReport: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_PRACTITIONER_REPORT_ITEM_APPOINTMENT_TIME:
      if (state.assistantReport.date) {
        return { ...state };
      }

      return {
        ...state,
        assistantReport: {
          ...state.assistantReport,
          date: action.payload.appointmentTime
        }
      }

    case CREATE_PRACTITIONER_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        assistantReport: action.payload.current.assistantReport,
      };

    case UPDATE_ASSISTANT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        assistantReport: action.payload.assistantReport,
      }


    default:
      return { ...state };
  }
};

export default assistantReportReducer;
