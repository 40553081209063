// @flow
import { all } from 'redux-saga/effects';

import layoutSaga from '@tmatt-tech/allaroundmassage_frontend_common/dist/redux/layout/saga';
import authSaga from '@tmatt-tech/allaroundmassage_frontend_common/dist/redux/auth/authSaga';
import notificationSaga from '@tmatt-tech/allaroundmassage_frontend_common/dist/redux/notification/notificationSaga';
import appMenuSaga from 'redux/appMenu/saga';
import uiSaga from "redux/ui/uiSaga";
import modelSaga from "redux/model/modelSaga";

export default function* rootSaga(getState: any): any {
  yield all([
    authSaga(),
    layoutSaga(),
    appMenuSaga(),
    uiSaga(),
    notificationSaga(),
    modelSaga(),
  ]);
}
