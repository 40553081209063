import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  CREATE_GIFT_CARD_DECLARATION,
  DELETE_GIFT_CARD_DECLARATION,
  FETCH_GIFT_CARD_DECLARATION,
  UPDATE_GIFT_CARD_DECLARATION
} from "redux/model/giftCardDeclarations/giftCardDeclaration/constants";
import {
  apiCreateGiftCardDeclaration,
  apiDeleteGiftCardDeclaration,
  apiFetchGiftCardDeclaration,
  apiUpdateGiftCardDeclaration
} from "api/giftCardDeclaration/apiGiftCardDeclaration";
import {
  createGiftCardDeclarationFailed,
  createGiftCardDeclarationSuccess,
  deleteGiftCardDeclarationFailed,
  deleteGiftCardDeclarationSuccess,
  fetchGiftCardDeclarationFailed,
  fetchGiftCardDeclarationSuccess,
  updateGiftCardDeclarationFailed,
  updateGiftCardDeclarationSuccess
} from "redux/model/giftCardDeclarations/giftCardDeclaration/giftCardDeclarationActions";
import { SAGA_WAITING_TIME, SAGA_WAITING_TIME_HALF } from "globalConstants";
import { generateErrorMessage, throwFrontError } from "helpers/errorUtils";
import {
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import {
  closeSecondLayerModal, startFirstLayerModalListening,
  startSecondLayerModalListening, closeFirstLayerModal
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";


function* giftCardDeclarationSaga() {
  yield all([
    fork(watchFetchGiftCardDeclaration),
    fork(watchUpdateGiftCardDeclaration),
    fork(watchCreateGiftCardDeclaration),
    fork(watchDeleteGiftCardDeclaration),
  ]);
}

// Fetch GiftCardDeclaration
export function* watchFetchGiftCardDeclaration() {
  yield takeEvery(FETCH_GIFT_CARD_DECLARATION, sagaFetchGiftCardDeclaration);
}

export function* sagaFetchGiftCardDeclaration({ payload }) {
  try {
    const response = yield call(apiFetchGiftCardDeclaration, payload);

    const { giftCardDeclaration } = response.data;
    yield put(fetchGiftCardDeclarationSuccess({ giftCardDeclaration }));

  } catch (e) {
    yield put(fetchGiftCardDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create GiftCardDeclaration
export function* watchCreateGiftCardDeclaration() {
  yield takeLeading(CREATE_GIFT_CARD_DECLARATION, sagaCreateGiftCardDeclaration);
}

export function* sagaCreateGiftCardDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateGiftCardDeclaration, payload);

    const { giftCardDeclaration } = response.data;

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());
    yield delay(SAGA_WAITING_TIME_HALF);

    yield put(createGiftCardDeclarationSuccess({ giftCardDeclaration }));
    yield put(toastSaved({ name: `GiftCardDeclaration` }));

  } catch (e) {
    yield put(createGiftCardDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update GiftCardDeclaration
export function* watchUpdateGiftCardDeclaration() {
  yield takeLeading(UPDATE_GIFT_CARD_DECLARATION, sagaUpdateGiftCardDeclaration);
}

export function* sagaUpdateGiftCardDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateGiftCardDeclaration, payload);

    const { giftCardDeclaration } = response.data;

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());
    yield delay(SAGA_WAITING_TIME_HALF);

    yield put(updateGiftCardDeclarationSuccess({ giftCardDeclaration }));
    yield put(toastSaved({ name: 'GiftCardDeclaration' }));

  } catch (e) {
    yield put(updateGiftCardDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete GiftCardDeclaration
export function* watchDeleteGiftCardDeclaration() {
  yield takeLeading(DELETE_GIFT_CARD_DECLARATION, sagaDeleteGiftCardDeclaration);
}

export function* sagaDeleteGiftCardDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteGiftCardDeclaration, payload);

    const { giftCardDeclaration } = response.data;
    yield put(deleteGiftCardDeclarationSuccess({ giftCardDeclaration }));
    yield put(toastDeleted({ name: 'GiftCardDeclaration' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteGiftCardDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default giftCardDeclarationSaga;
