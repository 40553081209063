import { LIST_STAFFS, LIST_STAFFS_FAILED, LIST_STAFFS_SUCCESS } from "redux/model/staffs/staffs/constants";

export const listStaffs = () => ({
  type: LIST_STAFFS,
  payload: {}
});

export const listStaffsSuccess = ({ staffs }) => ({
  type: LIST_STAFFS_SUCCESS,
  payload: { staffs }
});

export const listStaffsFailed = ({ error }) => ({
  type: LIST_STAFFS_FAILED,
  payload: { error }
});
