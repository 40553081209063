import { axios, generateRoute } from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_STAFF_API,
  LIST_STAFF_API,
  FETCH_STAFF_API,
  DELETE_STAFF_API,
  UPDATE_STAFF_API, FETCH_CURRENT_STAFF_API
} from "api/staff/constants";


export const apiListStaffs = () => {
  const { url, method } = LIST_STAFF_API;

  return axios({
    method,
    url,
  });
};

export const apiCreateStaff = ({ staff }) => {
  const { url, method } = CREATE_STAFF_API;

  return axios({
    method,
    url,
    data: staffParams(staff),
  });
};

export const apiFetchStaff = ({ staffId }) => {
  const { url, method } = FETCH_STAFF_API;

  return axios({
    method,
    url: generateRoute(url, staffId),
  });
};

export const apiUpdateStaff = ({ staff }) => {
  const { url, method } = UPDATE_STAFF_API;

  return axios({
    method,
    url: generateRoute(url, staff.id),
    data: staffParams(staff),
  });
};

export const apiDeleteStaff = ({ staff }) => {
  const { url, method } = DELETE_STAFF_API;

  return axios({
    method,
    url: generateRoute(url, staff.id),
  });
};

export const apiFetchCurrentStaff = () => {
  const { url, method } = FETCH_CURRENT_STAFF_API;

  return axios({
    method,
    url: url,
  });
};

const staffParams = (staff) => {
  let params = {
    email: staff.email,
    username: staff.username,
    firstName: staff.firstName,
    lastName: staff.lastName,
    mobile: staff.mobile,
    role: staff.role,
  }

  if (staff.password) {
    params = {
      ...params,
      password: staff.password,
    }
  }

  return params;
};
