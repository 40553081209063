
export const CREATE_LOGO_API = {
  url: `/api/logos`,
  method: 'post'
};

export const UPDATE_LOGO_API = {
  url: `/api/logos/:id`,
  method: 'put'
}
