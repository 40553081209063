import { axios, extractIdFrom } from "@tmatt-tech/allaroundmassage_frontend_common";
import { CREATE_NOTICE_API, LIST_NOTICE_API } from "api/notice/constants";
import _ from 'lodash';

export const apiListNotices = () => {
  const { url, method } = LIST_NOTICE_API;

  return axios({
    method,
    url,
  });
};

export const apiCreateNotice = ({ notice }) => {
  const { url, method } = CREATE_NOTICE_API;

  return axios({
    method,
    url,
    data: {
      notice: noticeParams(notice),
    },
  });
};

const noticeParams = (notice) => {
  return {
    subject: notice.subject,
    content: notice.content,
    receiver_ids: _.map(notice.receivers, (receiver) => {
      return extractIdFrom(receiver.id);
    }),
  }
};
