// @flow

import { combineReducers } from 'redux';
import modalReducers from '@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalReducers';
import imageEditorReducers
  from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/imageEditor/imageEditorReducers";
import formReducers from "redux/ui/form/formReducers";

export default combineReducers({
  modal: modalReducers,
  form: formReducers,
  imageEditor: imageEditorReducers,
});
