import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  generateErrorMessage,
  generateRoute,
  history,
  throwFrontError
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  CREATE_ASSISTANT_REPORT,
  FETCH_ASSISTANT_REPORT,
  UNLOCK_ASSISTANT_REPORT,
  UPDATE_ASSISTANT_REPORT
} from "redux/model/assistantReports/assistantReport/constants";
import {
  apiCreateAssistantReport,
  apiFetchAssistantReport,
  apiUnlockAssistantReport,
  apiUpdateAssistantReport
} from "api/assistantReport/assistantReportApi";
import {
  createAssistantReportFailed,
  fetchAssistantReportFailed,
  fetchAssistantReportSuccess,
  initAssistantReport,
  unlockAssistantReportFailed,
  unlockAssistantReportSuccess,
  updateAssistantReportFailed,
  updateAssistantReportSuccess
} from "redux/model/assistantReports/assistantReport/assistantReportActions";
import {
  toastDefault,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import { SHOW_ASSISTANT_REPORT_PATH } from "routes/assistantReport/constants";
import { listGiftCardsSuccess } from "redux/model/giftCards/giftCards/giftCardsActions";
import { OVERVIEW_DASHBOARD_PATH } from "routes/dashboard/constants";
import { closeSecondLayerModal } from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";
import { listSurveys } from "redux/model/surveys/surveys/surveysActions";
import { listConsultationsSuccess } from "redux/model/consultations/consultations/consultationsActions";


function* assistantReportSaga() {
  yield all([
    fork(watchFetchAssistantReport),
    fork(watchUpdateAssistantReport),
    fork(watchCreateAssistantReport),
    fork(watchUnlockAssistantReport),
  ]);
}

// Fetch AssistantReport
export function* watchFetchAssistantReport() {
  yield takeEvery(FETCH_ASSISTANT_REPORT, sagaFetchAssistantReport);
}

export function* sagaFetchAssistantReport({ payload }) {
  try {
    const response = yield call(apiFetchAssistantReport, payload);

    const { consultations, giftCards } = response.data;

    yield put(fetchAssistantReportSuccess({ current: response.data }));
    yield put(listSurveys({ clinicId: response.data.assistantReport.clinicId }));
    yield put(listGiftCardsSuccess({ giftCards }));
    yield put(listConsultationsSuccess({ consultations }));


  } catch (e) {
    yield put(fetchAssistantReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create AssistantReport
export function* watchCreateAssistantReport() {
  yield takeLeading(CREATE_ASSISTANT_REPORT, sagaCreateAssistantReport);
}

export function* sagaCreateAssistantReport({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateAssistantReport, payload);

    yield put(toastSaved({ name: 'Report' }));
    history.push(generateRoute(SHOW_ASSISTANT_REPORT_PATH, response.data.id));
    yield put(initAssistantReport());

  } catch (e) {
    yield put(createAssistantReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update AssistantReport
export function* watchUpdateAssistantReport() {
  yield takeLeading(UPDATE_ASSISTANT_REPORT, sagaUpdateAssistantReport);
}

export function* sagaUpdateAssistantReport({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateAssistantReport, payload);

    yield put(updateAssistantReportSuccess({ assistantReport: response.data }));
    yield put(toastSaved({ name: 'Report' }));

  } catch (e) {
    yield put(updateAssistantReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// unlock AssistantReport
export function* watchUnlockAssistantReport() {
  yield takeLeading(UNLOCK_ASSISTANT_REPORT, sagaUnlockAssistantReport);
}

export function* sagaUnlockAssistantReport({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    yield call(apiUnlockAssistantReport, payload);
    yield put(unlockAssistantReportSuccess(payload));
    yield put(toastDefault({ message: 'Report has been unlocked' }));
    yield put(closeSecondLayerModal());
    history.push(generateRoute(OVERVIEW_DASHBOARD_PATH));


  } catch (e) {
    yield put(unlockAssistantReportFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

export default assistantReportSaga;
