import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { CREATE_NOTICE, LIST_NOTICES } from "redux/model/notices/notices/constants";
import { apiCreateNotice, apiListNotices } from "api/notice/noticeApi";
import { listNoticesFailed, listNoticesSuccess } from "redux/model/notices/notices/noticesActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/allaroundmassage_frontend_common";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  toastDefault,
  toastError
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import {
  closeFirstLayerModal,
  startFirstLayerModalListening
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";


function* noticesSaga() {
  yield all([
    fork(watchListNotices),
    fork(watchCreateNotice),
  ]);
}

// List Notices
export function* watchListNotices() {
  yield takeEvery(LIST_NOTICES, sagaListNotices);
}

export function* sagaListNotices() {
  try {
    const response = yield call(apiListNotices);

    yield put(listNoticesSuccess({ notices: response.data }));

  } catch (e) {
    yield put(listNoticesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Create Notice
export function* watchCreateNotice() {
  yield takeLeading(CREATE_NOTICE, sagaCreateNotice);
}

export function* sagaCreateNotice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    yield call(apiCreateNotice, payload);

    yield put(toastDefault({ message: 'Message has been sent.' }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());

  } catch (e) {
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default noticesSaga;
