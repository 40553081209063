import {
  ASSIGN_CLINIC_ABN,
  ASSIGN_CLINIC_BUSINESS_NAME,
  ASSIGN_CLINIC_EMAIL,
  ASSIGN_CLINIC_LOCATION, ASSIGN_CLINIC_LOGO,
  ASSIGN_CLINIC_MOBILE,
  ASSIGN_CLINIC_NAME,
  ASSIGN_CLINIC_OVERVIEW,
  ASSIGN_CLINIC_PASSWORD,
  ASSIGN_CLINIC_PHONE,
  ASSIGN_CLINIC_USERNAME,
  ASSIGN_CLINIC_SHOP_MANAGER,
  ASSIGN_CLINIC_REGIONAL_MANAGER,
  FETCH_CLINIC,
  FETCH_CLINIC_FAILED,
  FETCH_CLINIC_SUCCESS,
  INIT_CLINIC, ASSIGN_CLINIC_VOUCHER_PREFIX
} from "redux/model/clinics/clinic/constants";

const INIT_STATE = {
  clinic: {},
  loading: true,
  error: null,
};

const clinicReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_CLINIC:
      return INIT_STATE;

    case FETCH_CLINIC:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CLINIC_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clinic: action.payload.clinic,
      };

    case FETCH_CLINIC_FAILED:
      return {
        ...state,
        clinic: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_CLINIC_SHOP_MANAGER:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          staffId: action.payload.staff.id,
        },
      };

    case ASSIGN_CLINIC_REGIONAL_MANAGER:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          regionalManagerId: action.payload.regionalManager.id,
        },
      };

    case ASSIGN_CLINIC_EMAIL:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          email: action.payload.email,
        },
      };

    case ASSIGN_CLINIC_USERNAME:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          username: action.payload.username,
        },
      };

    case ASSIGN_CLINIC_PASSWORD:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          password: action.payload.password,
        },
      };

    case ASSIGN_CLINIC_NAME:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          name: action.payload.name,
        },
      };

    case ASSIGN_CLINIC_PHONE:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          phone: action.payload.phone,
        },
      };

    case ASSIGN_CLINIC_MOBILE:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          mobile: action.payload.mobile,
        },
      };

    case ASSIGN_CLINIC_LOCATION:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          location: action.payload.location,
        },
      };

    case ASSIGN_CLINIC_BUSINESS_NAME:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          businessName: action.payload.businessName,
        },
      };

    case ASSIGN_CLINIC_ABN:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          abn: action.payload.abn,
        },
      };

    case ASSIGN_CLINIC_OVERVIEW:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          overview: action.payload.overview,
        },
      };

    case ASSIGN_CLINIC_LOGO:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          logo: action.payload.logo,
        },
      };

    case ASSIGN_CLINIC_VOUCHER_PREFIX:
      return {
        ...state,
        clinic: {
          ...state.clinic,
          voucherPrefix: action.payload.voucherPrefix,
        },
      };

    default:
      return { ...state };
  }
};

export default clinicReducer;
