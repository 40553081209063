
export const CREATE_CLINIC_API = {
  url: `/api/clinics`,
  method: 'post'
};

export const LIST_CLINIC_API = {
  url: `/api/clinics`,
  method: 'get',
}

export const FETCH_CLINIC_API = {
  url: `/api/clinics/:id`,
  method: 'get',
}

export const UPDATE_CLINIC_API = {
  url: `/api/clinics/:id`,
  method: 'put'
}

export const DELETE_CLINIC_API = {
  url: `/api/clinics/:id`,
  method: 'delete'
}
