import { ERROR_FORM_STAFF, LOADING_FORM_STAFF, RESET_FORM_STAFF } from "redux/ui/form/staff/constants";
import {
  CREATE_STAFF,
  CREATE_STAFF_FAILED,
  CREATE_STAFF_SUCCESS,
  UPDATE_STAFF, UPDATE_STAFF_FAILED, UPDATE_STAFF_SUCCESS
} from "redux/model/staffs/staff/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formStaffReducer = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_STAFF:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_STAFF:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_STAFF:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_STAFF:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_STAFF_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_STAFF:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_STAFF_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formStaffReducer;
