import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  CREATE_PRODUCT_GIFT_CARD,
  DELETE_PRODUCT_GIFT_CARD,
  FETCH_PRODUCT_GIFT_CARD,
  UPDATE_PRODUCT_GIFT_CARD
} from "redux/model/productGiftCards/productGiftCard/constants";
import {
  apiCreateProductGiftCard,
  apiDeleteProductGiftCard,
  apiFetchProductGiftCard,
  apiUpdateProductGiftCard
} from "api/productGiftCard/apiProductGiftCard";
import {
  createProductGiftCardFailed,
  createProductGiftCardSuccess,
  deleteProductGiftCardFailed,
  deleteProductGiftCardSuccess,
  fetchProductGiftCardFailed,
  fetchProductGiftCardSuccess,
  initProductGiftCard,
  updateProductGiftCardFailed,
  updateProductGiftCardSuccess
} from "redux/model/productGiftCards/productGiftCard/productGiftCardActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import { INDEX_PRODUCT_GIFT_CARDS_PATH } from "routes/productGiftCard/constants";
import {
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/toast/toastActions";
import {
  generateErrorMessage,
  throwFrontError,
  history,
  generateRoute
} from "@tmatt-tech/allaroundmassage_frontend_common";
import {
  startSecondLayerModalListening,
  closeSecondLayerModal,
} from "@tmatt-tech/allaroundmassage_frontend_common/dist/redux/ui/modal/modalActions";

function* productGiftCardSaga() {
  yield all([
    fork(watchFetchProductGiftCard),
    fork(watchUpdateProductGiftCard),
    fork(watchCreateProductGiftCard),
    fork(watchDeleteProductGiftCard),
  ]);
}

// Fetch ProductGiftCard
export function* watchFetchProductGiftCard() {
  yield takeEvery(FETCH_PRODUCT_GIFT_CARD, sagaFetchProductGiftCard);
}

export function* sagaFetchProductGiftCard({ payload }) {
  try {
    const response = yield call(apiFetchProductGiftCard, payload);

    const { productGiftCard } = response.data;
    yield put(fetchProductGiftCardSuccess({ productGiftCard }));

  } catch (e) {
    yield put(fetchProductGiftCardFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create ProductGiftCard
export function* watchCreateProductGiftCard() {
  yield takeLeading(CREATE_PRODUCT_GIFT_CARD, sagaCreateProductGiftCard);
}

export function* sagaCreateProductGiftCard({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateProductGiftCard, payload);

    const { productGiftCard } = response.data;
    yield put(createProductGiftCardSuccess({ productGiftCard }));
    yield put(toastSaved({ name: `ProductGiftCard` }));


    history.push(generateRoute(INDEX_PRODUCT_GIFT_CARDS_PATH));
    yield put(initProductGiftCard());


  } catch (e) {
    yield put(createProductGiftCardFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update ProductGiftCard
export function* watchUpdateProductGiftCard() {
  yield takeLeading(UPDATE_PRODUCT_GIFT_CARD, sagaUpdateProductGiftCard);
}

export function* sagaUpdateProductGiftCard({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateProductGiftCard, payload);

    const { productGiftCard } = response.data;
    yield put(updateProductGiftCardSuccess({ productGiftCard }));
    yield put(toastSaved({ name: 'ProductGiftCard' }));
    history.push(generateRoute(INDEX_PRODUCT_GIFT_CARDS_PATH));
    yield put(initProductGiftCard());

  } catch (e) {
    yield put(updateProductGiftCardFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete ProductGiftCard
export function* watchDeleteProductGiftCard() {
  yield takeLeading(DELETE_PRODUCT_GIFT_CARD, sagaDeleteProductGiftCard);
}

export function* sagaDeleteProductGiftCard({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteProductGiftCard, payload);

    const { productGiftCard } = response.data;
    yield put(deleteProductGiftCardSuccess({ productGiftCard }));
    yield put(toastDeleted({ name: 'ProductGiftCard' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteProductGiftCardFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default productGiftCardSaga;
